<script>
export default {
  name: 'NavbarNormal',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // 'active' is the current page
      // 'selected' is the last menu clicked

      selectedMainMenu: null, // menu object
      showSubmenu: false,
    };
  },
  computed: {
    activePath() {
      if (this.$route.path.includes('/info/forum')) return '/info/forum';
      return this.$route.path;
    },
    // menu object
    activeMenuItem() {
      return this.menus.find((menu) => menu.to === this.activePath);
    },
    isAdmin() {
      return !!this.$store.state.user.admin;
    },
    mainMenuArr() {
      return this.menus.reduce((out, menu) => {
        const index = out.findIndex((el) => el.main === menu.main);
        if (index === -1 && (this.isAdmin || !menu.adminOnly)) return [...out, menu];
        return out;
      }, []);
    },
    // all the submenus to the current main menu selection
    submenuArr() {
      return this.menus.filter((menu) => {
        return (
          this.selectedMainMenu &&
          this.selectedMainMenu.main === menu.main &&
          menu.subMenu &&
          (this.isAdmin || !menu.adminOnly)
        );
      });
    },
  },
  watch: {
    activePath() {
      // path changed.  If Problem or LessonDetails or Lecture page, no menu item should be selected.
      if (!this.activeMenuItem) this.selectedMainMenu = null;
    },
  },
  methods: {
    doLogout() {
      this.$store.dispatch('doLogout');
      if (!this.rememberUsername) this.username = '';
    },
    onMainMenuClick(menu) {
      if (menu.subMenu) {
        /* has submenu items */
        if (!this.selectedMainMenu) {
          this.selectedMainMenu = menu;
          this.showSubmenu = true;
          return;
        }
        if (this.selectedMainMenu.main === menu.main && this.showSubmenu) {
          this.showSubmenu = false;
          this.selectedMainMenu = null;
          return;
        }
        this.showSubmenu = false;
        setTimeout(() => {
          this.selectedMainMenu = menu;
          this.showSubmenu = true;
        }, 175);
      } else {
        /* no submenu items */
        this.showSubmenu = false;
        this.selectedMainMenu = menu;
        if (menu.to === this.activePath) return;
        this.$router.push(menu.to);
      }
    },

    onSubmenuClick(menu) {
      if (menu.sub === 'Logout') {
        this.$store.dispatch('doLogout');
        return;
      }
      this.showSubmenu = false;
      if (menu.to === this.activePath) return;
      this.selectedMainMenu = menu;
      this.$router.push(menu.to);
    },
  },
};
</script>

<template>
  <div>
    <nav>
      <!-- top row -->
      <div class="nav__main-menu">
        <div class="nav__main-menu__container">
          <div
            class="nav-logo"
            @click="onMainMenuClick(menus.find((menu) => menu.main === 'Home'))"
          >
            <img
              src="@/assets/images/stones_logo_02-B_black_40x40.png"
              alt="Go stone with GJ engraved"
            />
          </div>
          <!-- main menu -->
          <ul>
            <li
              v-for="menu in mainMenuArr"
              :key="menu.main"
              :class="{
                'active-main': activeMenuItem && activeMenuItem.main === menu.main,
                'selected-main': selectedMainMenu && selectedMainMenu.main === menu.main,
                'expanded-main': showSubmenu && selectedMainMenu.main === menu.main,
              }"
              @click="onMainMenuClick(menu)"
            >
              <font-awesome-icon
                v-if="menu.mainIcon"
                class="menu-icon"
                :icon="menu.mainIcon"
                size="lg"
              />
              {{ menu.main }}
            </li>
          </ul>
        </div>
      </div>

      <!-- second row = sub menu -->
      <div class="nav__sub-menu" :class="{ 'nav__sub-menu--show': showSubmenu }">
        <div class="nav__sub-menu__container">
          <div class="logo-space"></div>
          <ul>
            <li
              v-for="menu in submenuArr"
              :key="menu.sub"
              :class="{ 'active-sub': menu.to === activePath }"
              @click="onSubmenuClick(menu)"
            >
              {{ menu.sub }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
nav {
  color: var(--dark);
  line-height: 1.2;
  text-align: center;
}

.nav__main-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 3.5rem;
  width: 100%;
  background-color: var(--petal);
  z-index: 901;

  &__container {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: 1600px;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;

    & a {
      color: inherit;
      text-decoration: none;
    }

    & ul {
      width: 100%;
      height: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & li {
        position: relative;
        flex: 1 0 6rem;
        height: 100%;
        max-width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color, background-color 0.15s ease-in-out;
        border-right: 1px dotted var(--petal-light);

        &:last-of-type {
          border: none;
        }

        &:hover {
          background-color: var(--petal-dark);
          color: var(--light);
        }
      }
    }
  }
}

.nav__sub-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background);
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
  color: transparent;
  z-index: 900;
  border-bottom: 0.125rem solid var(--background-darker);

  &__container {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: 1600px;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;

    .logo-space {
      height: 100%;
      width: 10rem;
    }
  }

  &--show {
    color: inherit;
    transform: translateY(3.5rem);
  }

  & ul {
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;

    & > .current-page {
      color: var(--light);
      background-color: var(--background-darker);
      font-weight: 600;
    }

    & li {
      flex: 1 0 6rem;
      max-width: 10rem;
      height: 2.5rem;
      border-right: 1px solid var(--background-dark);
      transition-duration: 0.15s;
      transition-property: color, background-color;
      transition-timing-function: ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:last-of-type {
        border: none;
      }

      &:hover {
        background-color: var(--background-darker);
        color: var(--light);
      }
    }

    & a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.active-sub {
  background-color: var(--background-darker);
  color: var(--white);
}
// selected is the last main menu item clicked
.selected-main {
  background-color: var(--petal-dark);
  color: var(--light);
}
// active is the CURRENT PAGE
.active-main {
  background-color: var(--petal-dark);
  color: var(--white);
  font-weight: 600;
}

// if a main menu item has a submenu, it gets the expanded to make the little triangle
.expanded-main {
  &::before {
    content: '';
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    box-sizing: content-box;
    border-style: solid;
    border-width: 0 1rem 0.75rem 1rem;
    border-color: transparent transparent var(--background) transparent;
    background-color: transparent;
    transform: translateX(-1rem);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

.nav-logo {
  height: 100%;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.15s;
  transition-property: color, background-color;
  transition-timing-function: ease-in-out;
  & img {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.menu-icon {
  margin-right: 0.2rem;
}
</style>