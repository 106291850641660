export default {
  async setUsersNewsSeen({ commit, getters }: any, newsIdSeen: number): Promise<void> {
    commit('setUserNewsSeen', newsIdSeen);
    try {
      await getters.apiPut('fetch/news_seen', { data: { newsId: newsIdSeen } });
    } catch (e) {
      const err = e as Error;
      console.log(
        'an error occured while updating the "news seen" in the User table: ',
        err.message,
      );
    }
  },
};
