<script>
import LoginComponent from '@/components/LoginComponent';

export default {
  name: 'NavbarNormal',
  components: { LoginComponent },
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showLoginModal: false,
    };
  },
  computed: {
    activeMenuItem() {
      return this.menus.find((menu) => menu.to === this.$route.path);
    },
    mainMenuArr() {
      return this.menus.reduce((out, menu) => {
        const index = out.findIndex((el) => el.main === menu.main);
        if (index === -1 && (this.isAdmin || !menu.adminOnly)) return [...out, menu];
        return out;
      }, []);
    },
    filteredMainMenus() {
      if (this.hideLoginRegister)
        return this.mainMenuArr.filter(
          (menu) => menu.main !== 'Register' && menu.main !== 'Log In',
        );
      return this.menus;
    },
    hideLoginRegister() {
      if (this.$route.path === '/login_redirect') return true;
      return false;
    },
  },
  methods: {
    onMainMenuClick(menu) {
      if (menu.main === 'Log In') this.showLoginModal = true;
      else this.$router.push(menu.to);
    },
  },
};
</script>

<template>
  <div>
    <nav>
      <!-- top row -->
      <div class="nav__main-menu">
        <div class="nav__main-menu__container">
          <div class="nav-logo" @click="$router.push('/landing')">
            <img
              src="@/assets/images/stones_logo_02-B_black_40x40.png"
              alt="Go stone with GJ engraved"
            />
          </div>
          <!-- main menu -->
          <ul>
            <li
              v-for="menu in filteredMainMenus"
              :key="menu.main"
              :class="{
                'active-main': activeMenuItem && activeMenuItem.main === menu.main,
              }"
              @click="onMainMenuClick(menu)"
            >
              <font-awesome-icon
                v-if="menu.mainIcon"
                class="menu-icon"
                :icon="menu.mainIcon"
                size="lg"
              />
              {{ menu.main }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- start login modal -->
    <b-modal v-model="showLoginModal" hide-header-close hide-footer title="Login">
      <template #default>
        <login-component
          @pw-reset-clicked="showLoginModal = false"
          @register-clicked="showLoginModal = false"
          @failed-login="showLoginModal = false"
        />
      </template>
    </b-modal>
    <!-- end login modal -->
  </div>
</template>

<style lang="scss" scoped>
nav {
  color: var(--dark);
  line-height: 1.2;
  text-align: center;
}

.nav__main-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 3.5rem;
  width: 100%;
  background-color: var(--petal);
  z-index: 901;

  &__container {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: 1500px;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;

    & a {
      color: inherit;
      text-decoration: none;
    }

    & ul {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & li {
        position: relative;
        flex: 1 0 6rem;
        height: 100%;
        max-width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color, background-color 0.15s ease-in-out;
        border-right: 1px dotted var(--petal-light);

        &:last-of-type {
          border: none;
        }

        &:hover {
          background-color: var(--petal-dark);
          color: var(--light);
        }
      }
    }
  }
}

.nav__sub-menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background);
  width: 100%;
  height: 3rem;
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
  color: transparent;
  z-index: 900;
  border-bottom: 0.125rem solid var(--background-darker);

  &--show {
    color: inherit;
    transform: translateY(3.5rem);
  }

  & ul {
    margin: auto;
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 1500px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;

    & > .current-page {
      color: var(--light);
      background-color: var(--background-darker);
      font-weight: 600;
    }

    & li {
      flex: 1 0 6rem;
      max-width: 14rem;
      height: 2.5rem;
      border-right: 1px solid var(--background-dark);
      transition-duration: 0.15s;
      transition-property: color, background-color;
      transition-timing-function: ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-of-type {
        border: none;
      }

      &:hover {
        background-color: var(--background-darker);
        color: var(--light);
      }
    }

    & a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.active-sub {
  background-color: var(--background-darker);
  color: var(--white);
}
// selected is the last main menu item clicked
.selected-main {
  background-color: var(--petal-dark);
  color: var(--light);
}
// active is the CURRENT PAGE
.active-main {
  background-color: var(--petal-dark);
  color: var(--white);
  font-weight: 600;
}

// if a main menu item has a submenu, it gets the expanded to make the little triangle
.expanded-main {
  &::before {
    content: '';
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    box-sizing: content-box;
    border-style: solid;
    border-width: 0 1rem 0.75rem 1rem;
    border-color: transparent transparent var(--background) transparent;
    background-color: transparent;
    transform: translateX(-1rem);
  }
}

ul {
  list-style-type: none;
  margin: auto;
  padding: 0;
}

.nav-logo {
  height: 100%;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.15s;
  transition-property: color, background-color;
  transition-timing-function: ease-in-out;
  & img {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.menu-icon {
  margin-right: 0.2rem;
}
</style>