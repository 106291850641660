import { IVuexRootState } from '@/types';


export default{

  // --
  findTagIdFromTagText: (state: IVuexRootState) => (text: string) => {
    const row = state.tag.find(t => t.text === text);
    return row!.tag_id;
  },






}
