<script>
import NavRoot from '@/components/navbar/NavRoot.vue';
import { mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';

export default {
  name: 'App',
  components: { NavRoot },
  computed: {
    ...mapGetters(['apiGet', 'apiPut']),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    numFlashesWaiting() {
      // number of flash messages waiting to be displayed.  Watcher watches this.
      return this.$store.state.flash.length;
    },
  },
  watch: {
    async numFlashesWaiting(val) {
      // if there are flash messages to be displayed, display them.
      if (val > 0) {
        const flash = await this.$store.dispatch('getFlashMsg');
        this.$bvToast.toast(flash.message, {
          title: flash.title,
          toaster: 'b-toaster-top-center',
          autoHideDelay: flash.duration || 5000,
          variant: flash.variant || 'success',
          solid: true,
        });
      }
    },
  },
  created() {
    // calculate a "viewport height" that works on iPhones
    this.getScreenDimensions();
    window.addEventListener('resize', this.getScreenDimensions);

    // if there is a token in localStorage, then try log the user in.
    this.loginIfLocalToken();

    // 60 second timer (with counter in $store)
    setInterval(async () => {
      if (!this.isLoggedIn) return;
      this.$store.commit('setValue', {
        storeKey: 'now',
        storeValue: new Date(),
      });
      this.jwtRefresh();
    }, 300000); // 5 minutes

    // "prefetch" resource hints don't work for phones, so do it manually
    setTimeout(() => {
      import('@/views/ProblemPage.vue');
    }, 5000);
    setTimeout(() => {
      import('@/views/Lecture.vue');
    }, 10000);
  },

  methods: {
    getScreenDimensions() {
      // make a css variable attached to :root called --vh
      const vh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.$store.commit('setWindowHeightAndWidth');
    },
    jwtRefresh() {
      // check if the JWToken needs refreshing at an interval.
      const R = this.$store.getters.tokenRefreshDate;
      if (R) {
        const now = new Date();
        if (now > R) {
          // 'now' is greater than 'R' means we are past the refresh time
          this.$store.dispatch('refreshToken');
        }
      }
    },
    loginIfLocalToken() {
      const localToken = localStorage.getItem('token');
      if (localToken) {
        // check if token expiration date is valid.
        const now = new Date().valueOf(); // milliseconds since epoch
        const payload = jwtDecode(localToken); // payload.exp is seconds since epoch.

        if (payload.exp * 1000 > now) {
          // token not expired yet -- attempt auto login
          this.$store.dispatch('tokenPresentOnAppLoad', localToken);
        } else {
          // token expired
          this.$store.commit('pushFlash', {
            title: 'Login required',
            message: 'It has been too long since you were logged in, you must login again.',
            variant: 'info',
            duration: 10000,
          });
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <nav-root />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  min-height: calc(100 * var(--vh));
}

</style>
