import { IVuexRootState } from '@/types';


export default {

  /**
   * lesson list - filtered by difficulty slider and sorted
   * for use in "Next" and "Prev" links on Lesson Details page
   */
   filteredSortedLessons(state: IVuexRootState) {
    const filtered = state.joinedLessons.filter(jl => (
      jl.isLessonActive &&
      jl.lessonDifficulty! >= state.difficultySlider[0] &&
      jl.lessonDifficulty! <= state.difficultySlider[1]
    ))
    return filtered.sort((a, b) => {
      if (a.typeOrder! < b.typeOrder!) return -1;
      if (a.typeOrder! > b.typeOrder!) return 1;
      if (a.collectionOrder! < b.collectionOrder!) return -1;
      if (a.collectionOrder! > b.collectionOrder!) return 1;
      if (a.nameOrder! < b.nameOrder!) return -1;
      if (a.nameOrder! > b.nameOrder!) return 1;
      if (a.suffixOrder! < b.suffixOrder!) return -1;
      if (a.suffixOrder! > b.suffixOrder!) return 1;
      return 0;
    })
  },

  shortNameFromLessonId: (state: IVuexRootState)=>(lessonId: number)=>{
    const lesson = state.joinedLessons.find(jl=>jl.lessonId === lessonId);
    if (lesson!.lessonSuffix) return `${lesson!.lessonName} > ${lesson!.lessonSuffix}`;
    return lesson!.lessonName;
  }







}