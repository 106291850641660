/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { IJoinedProblem, IVuexRootState } from '@/types';

export default {
  changeJpsDeckIds(
    state: IVuexRootState,
    { problemIds, newDeckId }: { problemIds: number[]; newDeckId: number },
  ): void {
    problemIds.forEach((problemId) => {
      const record = state.joinedProblems.find((jp) => jp.problemId === problemId);
      if (!record) throw new Error('problem record not found');
      record.deckId = newDeckId;
    });
  },

  changeJpFilename(
    state: IVuexRootState,
    { problemId, newFilename }: { problemId: number; newFilename: string },
  ): void {
    const record = state.joinedProblems.find((jp) => jp.problemId === problemId);
    if (!record) throw new Error('problem record not found');
    record.filename = newFilename;
  },

  changeJpNextScheduledDate(
    state: IVuexRootState,
    { problemId, newDate }: { problemId: number; newDate: string },
  ): void {
    const record = state.joinedProblems.find((jp) => jp.problemId === problemId);
    if (!record) throw new Error('problem record not found');
    record.nextScheduledDate = newDate;
  },

  clearJoinedProblems(state: IVuexRootState) {
    state.joinedProblems.splice(0);
  },

  // --
  deleteOneJoinedProblem(state: IVuexRootState, problemId: number) {
    const index = state.joinedProblems.findIndex(
      (jp: IJoinedProblem) => jp.problemId === problemId,
    );
    if (index !== -1) state.joinedProblems.splice(index, 1);
  },

  // --
  jpPush(state: IVuexRootState, jpRecord: IJoinedProblem) {
    state.joinedProblems.push(jpRecord);
  },

  // deletes and replaces any existing records but if there is one single record with sgf, keep it.
  // (ProblemPage loads a single problem with sgf initially, then the rest of the table)
  loadJoinedProblems(state: IVuexRootState, rows: IJoinedProblem[]) {
    if (state.joinedProblems.length === 1 && state.joinedProblems[0].sgf) {
      const index = rows.findIndex((row) => row.problemId === state.joinedProblems[0].problemId);
      const minusOneRow = rows.splice(index, 1);
      state.joinedProblems = [...state.joinedProblems, ...minusOneRow];
    } else {
      state.joinedProblems.splice(0, state.joinedProblems.length, ...rows);
    }
  },

  // newRecord is a complete JP object
  replaceJoinedProblemRecord(
    state: IVuexRootState,
    { index, newRecord }: { index: number; newRecord: IJoinedProblem },
  ) {
    state.joinedProblems[index] = newRecord;
  },

  // --
  resetOneProblemToNewState(state: IVuexRootState, joinedProblemsArrayIndex: number) {
    const ef = state.user_params[0].review_factor;
    const modifiedJP = state.joinedProblems[joinedProblemsArrayIndex];
    modifiedJP.state = 0;
    modifiedJP.swapNumber = 0;
    modifiedJP.ef = ef;
    modifiedJP.lastSeen = '';
    modifiedJP.nextScheduledDate = '';
    state.joinedProblems.splice(joinedProblemsArrayIndex, 1, modifiedJP);
  },

  // toggle whether problem is in user's study book (own problems)
  toggleBookState(state: IVuexRootState, problemId: number): void {
    const problem = state.joinedProblems.find((jp: IJoinedProblem) => jp.problemId === problemId);
    if (problem) problem.isSelectedForStudy = problem.isSelectedForStudy ? 0 : 1;
  },

  // toggle if problem is made public
  togglePublicStatus(state: IVuexRootState, problemId: number): void {
    const problem = state.joinedProblems.find((jp: IJoinedProblem) => jp.problemId === problemId);
    if (problem) problem.public = problem.public ? 0 : 1;
  },

  // change is object with JP keys and values
  // eg: {ef: 2, state: 3, ...}
  updateJoinedProblemFields(
    state: IVuexRootState,
    { index, toChange }: { index: number; toChange: Partial<IJoinedProblem> },
  ) {
    Object.assign(state.joinedProblems[index], toChange);
  },
};
