import { IJoinedProblem } from '@/types';

interface StudentCount {
  problemId: number;
  numStudying: number;
}

export default {

  // this on can be deleted once "own problems" are gone
  // count how many students other than current user have added an own problem to TS book
  // FAULTY LOGIC - this assumes without checking that a user added their own problem.
  async countTsStudents({ getters }: any, problem: IJoinedProblem): Promise<number> {
    if (!problem.public) return 0;
    const response = await getters.apiGet('/trainingSystem/count_students', {
      params: { problemId: problem.problemId }
    })
    if (problem.isSelectedForStudy) return response.data - 1;
    return response.data
  },

  // --
  async deleteOwnProblem({ commit, dispatch, getters }: any, problemId: number): Promise<void> {
    await getters.apiDelete('/trainingSystem/own_problem', {
      data: { problemId }
    })
    commit('deleteOneJoinedProblem', problemId);
    commit('clearJoinedTagTable');
    await dispatch('fetchJoinedTags');
    commit('pushFlash', {
      title: 'Problem Deleted',
      message:  'Problem deleted successfully',
    })
  },

  // when a public problem is made private, anyone studying it loses access
  async removeOwnProblemFromOthersBooks({getters, commit}: any, problemId: number):Promise<void>{
    await getters.apiPut('/trainingSystem/remove_from_books', {
      data: { problemId }
    });
  },

  // toggle whether a problem is in user's book on server and local state (own problems)
  async toggleBookState({ getters, commit }: any, problemId: number): Promise<void> {
    commit('toggleBookState', problemId);
    await getters.apiPut('/trainingSystem/toggle_book', {
      data: { problemId }
    });
  },

  // toggle whether an own-problem is public
  async togglePublicStatus({ getters, commit }: any, problemId: number): Promise<void> {
    commit('togglePublicStatus', problemId);
    await getters.apiPut('/trainingSystem/toggle_public', {
      data: { problemId }
    });
  },











}
