import { IVuexRootState, IJoinedProblem } from '@/types';

export default {
  // return all cards in a deck
  cardsInDeck: (state: IVuexRootState) => (deckId: number): IJoinedProblem[] => {
    return state.joinedProblems.filter((jp) => jp.deckId === deckId);
  },

  // return the `joinedProblems` record that has problemId
  getJoinedProblemsRecord: (state: IVuexRootState) => (
    problemId: number,
  ): IJoinedProblem | undefined => {
    return state.joinedProblems.find((pb) => pb.problemId === problemId);
  },

  numLearningProblemsEnabled: (state: IVuexRootState): number => {
    // how many LEARNING (state 1||2) problems does user have enabled?
    let counter = 0;
    state.joinedProblems.forEach((problem) => {
      if (
        (problem.state === 1 || problem.state === 2) &&
        problem.isSelectedForStudy &&
        problem.isPublished
      )
        counter += 1;
    });
    return counter;
  },

  numReviewProblemsEnabled: (state: IVuexRootState): number => {
    // how many Review (state 3) problems does user have enabled?
    let counter = 0;
    state.joinedProblems.forEach((problem) => {
      if (problem.state === 3 && problem.isSelectedForStudy && problem.isPublished) counter += 1;
    });
    return counter;
  },

  isProblemInMyTsBook: (state: IVuexRootState) => (problemId: number): boolean => {
    return !!state.joinedProblems.find((pb) => pb.problemId === problemId)!.isSelectedForStudy;
  },
};
