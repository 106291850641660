import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { IVuexRootState } from '@/types';
import actions from '@/vuex/actions/actions';
import getters from '@/vuex/getters/getters';
import mutations from '@/vuex/mutations/mutations';


Vue.use(Vuex);


const store: StoreOptions<IVuexRootState> = {
	state: {
		flash: [],  // {title, message [, variant] [, duration] } defaults:  variant='success', duration=5000
		now: new Date(), // updates once per minute (from App.vue).
		token: '',

		// info from database tables.
		custom_achievement_text: [],
		donation: [],
		enabled_subtitle: [],
		gift_certificate: [],
		invoice: [],
		joinedDecks: [],
		joinedLessons: [],
		joinedProblems: [],
		joinedViews: [], // lecture views
		language: [],  // subtitle languages
		membership: [],  // admin will need all records
		news: [],  // load as needed (currently loading only one record)
		stats: [],
		subscription: [],
		subtitle: [],
		teacher: [],
		user: null,
		user_params: [],
		view_ticket: [],

		// tags
		tag: [],
		joinedTags: [],  // from tag2pb table
		myTags: [],  // from tag2us table

		// forum
		category: [
			{ cat_id: 1, ord: 0, title: 'Website', description: 'Website questions, comments and suggestions...' },
			{ cat_id: 2, ord: 2, title: 'Everything else', description: 'All other topics' },
			{ cat_id: 3, ord: 1, title: 'Go discussions', description: 'Go questions, discussions, lesson requests...' },
		],
		topic: [],
		post: [],

		//
		nlQueue: [],  // New Learn queue ready for study  (FIFO)
		lrQueue: [],  // Learn Review queue ready for study
		qlQueue: [],  // QuickLook queue ready for study
		doneStack: [], // probs move from other queues to here when done (LIFO)


		// state from LessonListPane that needs to persist
		difficultySlider: [1, 11],
		hideWatchedLectures: false,
		treeSelections: {
			tree1: null,
			tree2: null,
			tree3: null,
		},

		// homepage.
		homepageProgBarPrevVal: 0,
		homepageFanfarePlayed: null,

		// prefetched data to speed up loading of Lecture page
		prefetchSgf: '',
		prefetchJson: [],
		prefetchLessonId: 0,
		lectureToken: '',

		// other state info
    newsFetchedAt: null,  // time of last news fetch
		returnToUrl: '/',  // at end of a quicklook or bookmark - where to go
		ip: '',
		countryCode: '',
		city: '',
		isIOS: ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
			navigator.platform,
			) ||
			// iPad on iOS 13 detection
			(navigator.userAgent.includes('Mac') && 'ontouchend' in document),

		showInactiveUnpublished: false, // show inactive or unpublished lessons and problems
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight,
		decksFetchedAt: new Date('2000-01-01'),
		},

		mutations,
		getters,
		actions,
	};



	export default new Vuex.Store<IVuexRootState>(store);
