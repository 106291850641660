import Vue from 'vue';
import { format, parseISO } from 'date-fns';
import {
  ModalPlugin,
  PopoverPlugin,
  ToastPlugin,
  TooltipPlugin,
  /* The following are being imported as components locally, not globally to reduce the App-Vendor chunk size
    FormFilePlugin,
    FormRadioPlugin,
    FormTextareaPlugin,
    TabsPlugin,
  */
} from 'bootstrap-vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/vuex';
import { IJoinedProblem } from '@/types';

import '@/scss-old/custom.scss'; // place to set Bootstrap variables.

// https://github.com/FortAwesome/vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faBook,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faEye,
  faFastForward,
  faFileAudio,
  faFileDownload,
  faHome,
  faInfo,
  faInfoCircle,
  faLink,
  faMinus,
  faPen,
  faPlay,
  faPause,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRunning,
  faSearch,
  faShoppingCart,
  faSlidersH,
  faStepBackward,
  faStepForward,
  faUndo,
  faUser,
  faUserEdit,
  faVolumeUp,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowRight,
  faBook,
  faCaretDown,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faDownload,
  faEye,
  faFastForward,
  faFileAudio,
  faHome,
  faInfo,
  faInfoCircle,
  faMinus,
  faPause,
  faPen,
  faPlay,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSearch,
  faShoppingCart,
  faSlidersH,
  faStepBackward,
  faStepForward,
  faLink,
  faFileDownload,
  faRunning,
  faUndo,
  faUser,
  faUserEdit,
  faVolumeUp,
  faWalking,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Register a global custom directive called `v-focus`
// example:  <input v-focus ... />
Vue.directive('focus', {
  inserted(el) {
    el.focus()
  }
})

// set this to false to prevent the production tip on startup
Vue.config.productionTip = true;

Vue.use(ModalPlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);

Vue.filter('thousands', (num: number) => {
  // add thousands separator (local sensitive)
  let locale = 'en-US';
  if (navigator.languages !== undefined) [locale] = navigator.languages;
  else locale = navigator.language;
  return num.toLocaleString(locale);
});

// formats a "2007-1-15" or "2007-01-15T02:36:00.000Z" from the database into "January 15, 2007"
Vue.filter('mdyDate', (dbDate: string) => {
  try{
    return format(parseISO(dbDate), 'MMM d y')
  }catch(e){
    return '';
  }
});

Vue.filter('goColor', (c: number) => {
  if (c === 1) return 'Black';
  if (c === -1) return 'White';
  return 'Invalid color code';
});

Vue.filter('goRank', (r: number | string) => {
  const s = Number(r);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(s)) return 'unknown';
  if (s < 0) return `${-s} kyu`;
  if (s === 9) return 'pro';
  return `${s + 1} dan`;
});

Vue.filter('noEmail', (name: string) => {
  return name.split('@')[0];
});

Vue.filter('minSec', (seconds: number) => {
  const minutes = Math.floor(seconds / 60).toString();
  const wholeSeconds = Math.floor(seconds % 60).toString();
  const twoDigits = `00${wholeSeconds}`.slice(-2);
  return `${minutes}:${twoDigits}`;
});

Vue.filter('pbId2Name', (problemId: number) => {
  return store.state.joinedProblems.find((jp: IJoinedProblem)=>jp.problemId === problemId)!.filename!.split('.')[0];
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
