import { IVuexRootState } from '@/types';


export default {
  
  menuStyle(state: IVuexRootState){
    if (state.windowWidth < 666) return 'mobile';
    return 'wide';
  },



}