/* eslint-disable no-param-reassign */
import Vue from 'vue';
import JoinedDecks from './MJoinedDecks';
import JoinedLessons from './MJoinedLessons';
import JoinedProblems from './MJoinedProblems';
import Membership from './MMembership';
import News from './MNews';
import Stats from './MStats';
import Tags from './MTags';
import TS from './MTS';
import User from './MUser';

export default {
  ...JoinedDecks,
  ...JoinedLessons,
  ...JoinedProblems,
  ...Membership,
  ...News,
  ...Stats,
  ...Tags,
  ...TS,
  ...User,

  addComment(state, payload) {
    state.comment.push(payload);
  },

  /**
   * Add additional rows to a table without deleting existing
   * @param {*} tableName name of table in $store as a string
   * @param {*} rows array of table rows to append
   */
  appendRows(state, { tableName, rows }) {
    if (tableName === 'joinedLessons' && state.joinedLessons.length > 1) return;
    state[tableName] = [...state[tableName], ...rows];
  },


  // change values in an object or an array of objects
  // "table" is a string
  // "change" is an object that looks like {problems: 5, state: 2, ...}
  // "index" number - is optional.
  changeExistingStateValue(state, { table, change, index }) {
    const keys = Object.keys(change);
    keys.forEach(key => {
      if (index === undefined) {
        Vue.set(state[table], key, change[key]);
      } else {
        Vue.set(state[table][index], key, change[key]);
      }
    })
  },

  /**
   * delete one item from an array at 'index'.
   */
  deleteArrayItem(state, { tableName, index }) {
    state[tableName].splice(index, 1);
  },

  fanfareNotPlayedYetToday(state) {
    state.homepageFanfarePlayed = false;
  },

  initialTableLoad(state, { tableName, rows }) {
    state[tableName] = rows;
  },

  lessonPrefetchClear(state) {
    state.prefetchSgf = '';
    state.prefetchJson = [];
    state.prefetchLessonId = 0;
    state.lectureToken = '';
  },

  lessonPrefetchSet(state, { sgf, json, lectureToken, lessonId }) {
    if (state.prefetchLessonId !== lessonId) {
      state.prefetchSgf = '';
      state.prefetchJson = [];
      state.prefetchLessonId = 0;
      state.lectureToken = '';
    }
    if (sgf && sgf.length) state.prefetchSgf = sgf;
    if (json && json.length) state.prefetchJson = json;
    if (lectureToken && lectureToken.length) state.lecturetoken = lectureToken;
    state.prefetchLessonId = lessonId;
  },

  // add a flash object to flash queue
  // payload = {title, message [, variant = 'success'] [, duration = 5000]}
  pushFlash(state, payload) {
    state.flash.push(payload);
  },

  pushRecord(state, { tableName, record }) {
    state[tableName].push(record);
  },

  returnToUrlClear(state){
    state.returnToUrl = '/';
  },

  returnToUrlSet(state, url){
    state.returnToUrl = url;
  },

  setValue(state, { storeKey, storeValue }) {
    state[storeKey] = storeValue;
  },

  // window height and width - these get called by event listener in App.vue
  setWindowHeightAndWidth(state) {
    state.windowWidth = window.innerWidth;
    state.windowHeight = window.innerHeight;
  },

  // remove a flash object from flash queue
  shiftFlash(state) {
    return state.flash.shift();
  },

  unshiftItem(state, { tableName, item }) {
    state[tableName].unshift(item);
  },




}
