import { IJoinedProblem, IVuexRootState } from '@/types';
import { addMinutes, endOfToday, isBefore, parseISO } from 'date-fns';


export default {

  /**
   * "MyProblems" are all problems enabled for TS study regardless of due dates.
   * include "own" problems that have been selected for study
   */
  myNewPs(state: IVuexRootState):IJoinedProblem[] {
    return state.joinedProblems.filter(jp => {
      if (jp.state !== 0) return false;
      if (!jp.isSelectedForStudy) return false;
      if (state.showInactiveUnpublished) return true;
      if (jp.author) return true;
      if (jp.isPublished && jp.isLessonActive) return true;
      return false;
    });
  },
  myLearnPs(state: IVuexRootState):IJoinedProblem[] {
    return state.joinedProblems.filter(jp => {
      if (jp.state !== 1 && jp.state !==2) return false;
      if (!jp.isSelectedForStudy) return false;
      if (state.showInactiveUnpublished) return true;
      if (jp.author) return true;
      if (jp.isPublished && jp.isLessonActive) return true;
      return false;
    });
  },
  myReviewPs(state: IVuexRootState):IJoinedProblem[] {
    return state.joinedProblems.filter(jp => {
      if (jp.state !== 3) return false;
      if (!jp.isSelectedForStudy) return false;
      if (state.showInactiveUnpublished) return true;
      if (jp.author) return true;
      if (jp.isPublished && jp.isLessonActive) return true;
      return false;
    });
  },

  /**
   * "MyDueProblems" are due for TS study.
   * "New" problems don't have a due date, so aren't included here.
   */
  myDueLearnPs(state: IVuexRootState, getters: any):IJoinedProblem[] {
    return getters.myLearnPs.filter((jp: IJoinedProblem)=>(
      isBefore(parseISO(jp.nextScheduledDate!), endOfToday()) &&
      // lastSeen + learningInterval1(minutes)  <-- IS BEFORE -->  now
      isBefore(addMinutes(parseISO(jp.lastSeen!), state.user_params[0].learning_interval1), state.now)
    ));
  },
  myDueReviewPs(_state: IVuexRootState, getters: any):IJoinedProblem[] {
    return getters.myReviewPs.filter((jp: IJoinedProblem)=>
      isBefore(parseISO(jp.nextScheduledDate!), endOfToday())
    )
  },














}
