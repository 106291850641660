import { IMembership, IVuexRootState } from '@/types';
import { addDays, formatISO, isAfter, max, parseISO } from 'date-fns';


export default {

  currentMemberships(state: IVuexRootState) {
    if (state.membership.length === 0) return [];
    return state.membership.filter((m) => m.iv_id && isAfter(parseISO(m.validity), new Date()));
  },

  // for transition, ANY valid membership authorize Lectures.
  hasValidLectureMembership(state: IVuexRootState) {
    if (state.membership.length === 0) return false;
    if (state.membership.some((mbr) => isAfter(addDays(parseISO(mbr.validity), 1), new Date()) && mbr.iv_id)) return true;
    return false;
  },

  invoicedMemberships: (state: IVuexRootState): IMembership[] =>
    state.membership.filter(mbr => mbr.iv_id)
  ,

  invoicedMembershipsLatestValidityDate: (_state: IVuexRootState, getters: any): string => {
    const dates: Date[] = getters.invoicedMemberships.map((im: IMembership) => parseISO(im.validity))
    return formatISO(max(dates))
  },

  nextAutopayMembershipRecord: (state: IVuexRootState): IMembership | null => {
    const records = state.membership
      .filter(m => (!m.iv_id && !m.user_unsubscribed && !m.pmt_failed));
    if (!records.length) return null;
    if (records.length > 1) throw new Error('multiple autopay eligible records found');
    return records[0];
  },




}