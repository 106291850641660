import { formatISO } from 'date-fns';
import Auth from './AAuth';
import Fetch from './AFetch';
import JoinedDecks from './AJoinedDecks';
import JoinedLessons from './AJoinedLessons';
import JoinedProblems from './AJoinedProblems';
import Lecture from './ALecture';
import Membership from './AMembership';
import News from './ANews';
import OwnProblems from './AOwnProblems';
import Payment from './APayment';
import Tags from './ATags';
import TS from './ATS';
import User from './AUser';


export default {
  ...Auth,
  ...Fetch,
  ...JoinedDecks,
  ...JoinedLessons,
  ...JoinedProblems,
  ...Lecture,
  ...Membership,
  ...News,
  ...OwnProblems,
  ...Payment,
  ...Tags,
  ...TS,
  ...User,

  putCollections({ commit, getters }, collectionsArray) {
    try {
      getters.apiPut('order/collections', { data: { collectionsArray } })
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while sending collection order changes to the database:',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  putNameOrders({ commit, getters }, namesArray) {
    try {
      getters.apiPut('order/names', { data: { namesArray } });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while sending name order changes to the database:',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  putSuffixOrders({ commit, getters }, suffixArray) {
    try {
      getters.apiPut('order/suffixes', { data: { suffixArray } });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while sending suffix order changes to the database:',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  /**
   * reordering display of "types" (top level of lesson tree)
   */
  putTypes({ commit, getters }, typesArray) {
    try {
      getters.apiPut('order/types', { data: { typesArray } });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while sending type order changes to the database:',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },


  /**
   * user is about to watch a lecture.  Update all tables accordingly:
   * update views table
   * update viewed_lecture table
   * and joinedLessons table needs updating so you can see viewed lectures
   * in the lesson list accordion
   */
  updateViewedLecture({ state, commit, getters }, lessonId) {
    const index = state.joinedLessons.findIndex((jl) => jl.lessonId === lessonId);
    const lsn = state.joinedLessons[index];

    commit('changeExistingStateValue', {
      table: 'joinedLessons',
      change: { userViewedLecture: 1 },
      index,
    });

    commit('pushRecord', {
      tableName: 'joinedViews',
      record: {
        collection: lsn.collectionName,
        lessonId: lsn.lessonId,
        lessonName: lsn.lessonName,
        lessonSuffix: lsn.lessonSuffix,
        type: lsn.typeName,
        whenViewed: formatISO(new Date()),
      },
    });

    try {
      getters.apiPut('lessons/watch', { data: { lessonId, ip: state.ip } });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while updating viewed lecture records:',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  /** ****************** */
  /**       Flash      * */
  /** ****************** */
  getFlashMsg({state, commit}) {
    if (state.flash.length === 0) return null;
    const flashMsg = state.flash[0];
    commit('shiftFlash');
    return flashMsg;
  },

  /** ******************************** */
  /**      Helpers / refactors      * */
  /** ******************************** */

  // save all the tables to state variables
  loadState(context, response) {
    response.data.tablesArray.forEach((table) => {
      context.commit('initialTableLoad', table);
    });
  },

  /**
   * Load table data from database into Store AND localStorage
   *
   * @param tablesArray: { tableName: string; rows: any }[]
   */
  tablesArrayToStoreAndLocalStorage(context, tablesArray) {
    const token = localStorage.getItem('token');

    // tablesArray has three items:  membership, user, view_ticket
    tablesArray.forEach((table) => {
      context.commit('initialTableLoad', table);
      if (token) localStorage.setItem(table.tableName, JSON.stringify(table.rows));
    });
  },
};
