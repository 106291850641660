import { lightFormat } from 'date-fns';

export default {
  async getCustomAchText({ state, commit, getters }) {
    if (state.custom_achievement_text.length > 0) return;
    try {
      const response = await getters.apiGet('fetch/achievement_text');
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching custom_achievement_text:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },

  async getEnabledSubtitle({ state, getters, commit }, forceCache = false) {
    // get subtitles from DB.  It's a small list, so grab them all
    let queryString = '';
    if (forceCache) {
      queryString = `?${Math.round(Math.random() * 100)}=${Math.round(Math.random() * 100)}`;
    }
    if (state.enabled_subtitle.length > 0 && !forceCache) return;
    try {
      const response = await getters.apiGet(`fetch/enabled_subtitle${queryString}`);
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching enabled_subtitles:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },


  // Get all ungotten forum table data
  async getForum({ state, commit, getters }) {
    // api will return rows with higher id's

    const maxCatId = state.category.reduce((max, cur) => Math.max(max, cur.cat_id), -1);
    const maxPostId = state.post.reduce((max, cur) => Math.max(max, cur.post_id), -1);
    const maxTopicId = state.topic.reduce((max, cur) => Math.max(max, cur.topic_id), -1);

    try {
      const response = await getters.apiGet(
        `fetch/forum/${maxCatId}/${maxTopicId}/${maxPostId}`,
      );
      // put the response into the $store
      commit('appendRows', {
        tableName: 'category',
        rows: response.data.category,
      });
      commit('appendRows', {
        tableName: 'topic',
        rows: response.data.topic,
      });
      commit('appendRows', {
        tableName: 'post',
        rows: response.data.post,
      });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching forum posts:',
        message: e.message,
        variant: 'warning',
        duration: 3000,
      });
    }
  },

  /**
   * Fetch tables for the user's history page:
   *    joinedViews has the lectures viewed
   *    viewTickets has the lectures rental period when bought with a credit or lecture credit
   */
  async getHistory({ state, commit, getters }) {
    if (state.joinedViews.length && state.view_ticket.length) return;
    try {
      const response = await getters.apiGet('fetch/history');
      response.data.forEach((el) => {
        commit('initialTableLoad', el);
      });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching history:',
        message: e.message,
        variant: 'warning',
        duration: 3000,
      });
    }
  },



  async getLanguage({ state, commit, getters }) {
    // get subtitles from DB.  It's a small list, so grab them all
    if (state.language.length > 0) return;
    try {
      const response = await getters.apiGet('fetch/language');
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching languages:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },

  async getLeaderboard({ commit, getters }) {
    try {
      // api is caching somewhere.  query param is to kill the caching.
      const [cache] = (Math.random() * 1000).toString().split('.');
      const today = lightFormat(new Date(), 'y-MM-dd');
      const response = await getters.apiGet('fetch/leaderboard', {
        params: {
          today,
          cache,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching leaderboard:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
      return { success: false };
    }
  },

  async getSubscriptions({ state, getters, commit }) {
    if (state.subscription.length > 0) return;
    try {
      const response = await getters.apiGet('fetch/subscriptions');
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching subscription list:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },

  async getTeachers({ state, commit, getters }) {
    if (state.teacher.length > 0) return;
    try {
      const response = await getters.apiGet('fetch/teacher');
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching teacher list:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },

  async getUserParams({ state, commit, getters }) {
    if (state.user_params.length > 0) return;
    try {
      const response = await getters.apiGet('user/params');
      commit('initialTableLoad', response.data);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching user parameters:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },
};
