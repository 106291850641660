<script>
export default {
  name: 'MobileNav',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showMainMenu: false,
      showSubmenu: false,
      selectedMainMenu: null,
    };
  },
  computed: {
    currentMenuArrayItem() {
      return this.menus.find((menu) => menu.to === this.currentPath);
    },
    currentPath() {
      return this.$route.path;
    },
    isAdmin() {
      return !!this.$store.state.user.admin;
    },
    // main menu
    mainMenuArr() {
      return this.menus.reduce((out, menu) => {
        const index = out.findIndex((el) => el.main === menu.main);
        if (index === -1 && (this.isAdmin || !menu.adminOnly)) return [...out, menu];
        return out;
      }, []);
    },
    // all the submenus to the current main menu selection
    submenuArr() {
      return this.menus.filter((menu) => {
        return (
          this.selectedMainMenu &&
          menu.main === this.selectedMainMenu.main &&
          menu.subMenu &&
          (this.isAdmin || !menu.adminOnly)
        );
      });
    },
  },
  methods: {
    doLogout() {
      this.selectedMainMenu = null;
      this.$store.dispatch('doLogout');
      if (!this.rememberUsername) this.username = '';
    },
    onGoBackClick() {
      this.showSubmenu = false;
      this.showMainMenu = true;
    },
    onHamburgerClick() {
      if (this.showSubmenu) {
        this.showSubmenu = false;
        this.showMainMenu = false;
      } else {
        this.showMainMenu = !this.showMainMenu;
      }
      this.selectedMainMenu = null;
    },
    onMainMenuClick(menu) {
      if (menu.subMenu) {
        /* has submenu items */

        // clicks on currently selected main menu - just hide menu
        if (this.selectedMainMenu && this.selectedMainMenu.main === menu.main) {
          this.selectedMainMenu = null;
          this.showMainMenu = false;
          return;
        }

        // show the new submenu
        this.selectedMainMenu = menu;
        this.showMainMenu = false;
        this.showSubmenu = true;
      } else {
        /* no submenu items */

        // close menus and navigate to page
        this.selectedMainMenu = null;
        this.showMainMenu = false;
        this.showSubmenu = false;
        if (menu.to === this.currentPath) return;
        this.$router.push(menu.to);
      }
    },

    onSubmenuClick(menu) {
      this.selectedMainMenu = null;
      this.showMainMenu = false;
      this.showSubmenu = false;
      if (menu.sub === 'Logout') {
        this.$store.dispatch('doLogout');
        return;
      }
      if (menu.to === this.currentPath) return;
      this.$router.push(menu.to);
    },
  },
};
</script>

<template>
  <nav>
    <div class="top-row">
      <div class="nav-logo" @click="onMainMenuClick(mainMenuArr[0])">
        <img
          src="@/assets/images/stones_logo_02-B_black_40x40.png"
          alt="Go stone with GJ engraved"
        />
      </div>
      <div class="hamburger" @click="onHamburgerClick">
        <div :class="{ x: showMainMenu || showSubmenu }"></div>
      </div>
    </div>
    <!-- main menu starts here -->
    <ul
      class="main-menu"
      :class="{
        show: showMainMenu,
        'hide-left': showSubmenu,
      }"
    >
      <li v-for="menu in mainMenuArr" :key="menu.main" @click="onMainMenuClick(menu)">
        <div class="icon">
          <font-awesome-icon
            v-if="menu.mainIcon"
            class="menu-icon"
            :icon="menu.mainIcon"
            size="lg"
          />
        </div>
        <div class="text">
          {{ menu.main }}
        </div>
        <div class="icon">
          <font-awesome-icon
            v-if="menu.subMenu"
            class="menu-icon"
            :icon="['fa', 'chevron-right']"
            size="lg"
          />
        </div>
      </li>
    </ul>
    <!-- submenu starts here -->
    <ul class="sub-menu" :class="{ show: showSubmenu }">
      <li @click="onGoBackClick">
        <div class="icon">
          <font-awesome-icon class="menu-icon" :icon="['fa', 'chevron-left']" size="lg" />
        </div>
        <div class="text">Go Back</div>
        <div class="icon"></div>
      </li>
      <li v-for="menu in submenuArr" :key="menu.sub" @click="onSubmenuClick(menu)">
        <div class="icon">
          <!-- add submenu icons here -->
        </div>
        <div class="text">
          {{ menu.sub }}
        </div>
        <div class="icon">
          <!-- end icon? -->
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  color: var(--dark);
  width: 100%;
}

.top-row {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: var(--petal);
  width: 100%;
  padding: 0 1rem;
}

.hamburger {
  height: 3rem;
  width: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  & div {
    width: 2.3rem;
    border-top: 0.1875rem solid var(--dark);
    border-radius: 0.09375rem;
    position: relative;
    transition: transform 0.2s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      bottom: 0.75rem;
      width: 2.3rem;
      border-top: 0.1875rem solid var(--dark);
      border-radius: 0.09375rem;
      transition: transform 0.2s ease-in-out;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -0.75rem;
      width: 2.3rem;
      border-top: 0.1875rem solid var(--dark);
      border-radius: 0.09375rem;
      transition: transform 0.2s ease-in-out;
    }
  }
}

.main-menu {
  position: absolute;
  top: 3.5rem;
  z-index: 900;
  width: 100%;
  list-style: none;
  background-color: var(--petal);
  color: var(--dark);
  padding: 1rem 0 0 0;
  z-index: 1000;
  transform-origin: top left;
  transform: rotateY(90deg);
  transition: transform 0.3s ease-in-out;

  & li {
    border-top: 1px dotted var(--petal-light);

    &:hover {
      background-color: var(--petal-dark);
      color: var(--light);
    }
  }
}

.sub-menu {
  position: absolute;
  top: 3.5rem;
  z-index: 900;
  width: 100%;
  list-style: none;
  background-color: var(--background);
  color: var(--dark);
  padding: 0;
  z-index: 1000;
  transform-origin: top right;
  transform: rotateY(90deg);
  transition: transform 0.3s ease-in-out;
  border-bottom: 2px solid var(--background-darker);

  & li {
    border-top: 1px dotted var(--background-darker);

    &:hover {
      background-color: var(--background-darker);
      color: var(--light);
    }
  }
}

li {
  font-size: 1.2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    flex: 0 0 2rem;
  }
  .text {
    flex: 1 0 10rem;
  }
}
.show {
  transform: rotateY(0);
}

.hide-left {
  transform: rotateY(90deg);
}

.x {
  transform-origin: center;
  transform: rotate(45deg);
  &::before {
    transform: translateY(0.75rem) rotate(90deg);
  }
  &::after {
    transform: translateY(-0.75rem) rotate(-90deg);
  }
}

img {
  height: 2.5rem;
  width: 2.5rem;
}
</style>