import { IVuexRootState } from '@/types';


export default {

  findSubscriptionById: (state: IVuexRootState) => 
    (sb_id: number) => state.subscription.find(s => s.sb_id === sb_id),






}