/* eslint-disable no-use-before-define */
import {
  differenceInCalendarDays,
  differenceInMilliseconds,
  formatISO,
  lightFormat,
  parseISO,
} from 'date-fns';
import { addValidityHash } from '@/pure/auth';

export default {
  async addProblemsToBookByLessonId({ commit, dispatch, getters }, lessonId) {
    await dispatch('initProblemState', lessonId);
    commit('addProblemsToBookByLessonId', lessonId);
    getters.apiPut('trainingSystem/add-by-lesson', { data: { lessonId } });
  },

  async addProblemsToBookByProblemIds({ commit, getters }, problemIds) {
    await getters.apiPut('/trainingSystem/book-by-ids', { data: problemIds });
    problemIds.forEach((id) => {
      commit('addProblemToBookByProblemId', id);
    });
  },

  /**
   * get sgfs from server and add to joinedProblem records
   * "queue" is an array of problemIds
   */
  async fetchSgfs({ state, getters, commit }, queue) {
    // if user doesn't have membership, don't fetch
    if (!getters.isAuthForTrainingSystem) return;

    // if a problem in the queue already has an sgf, do not re-fetch.
    const problemIdsNeedingSgfs = queue.filter((id) => {
      const jp = state.joinedProblems.find((jpr) => jpr.problemId === id);
      return !jp.sgf;
    });

    // if nothing to fetch, exit
    if (problemIdsNeedingSgfs.length === 0) return;

    // problem string is comma delimited problemId's that need sgfs
    const problemString = problemIdsNeedingSgfs.toString();

    let response;
    try {
      response = await getters.apiGet(`fetch/sgfs?ids=${problemString}`);
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching problems:',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
    response.data.forEach((dataEl) => {
      // add sgf to joinedProblems
      const i = state.joinedProblems.findIndex((jp) => jp.problemId === dataEl.id);
      commit('changeExistingStateValue', {
        table: 'joinedProblems',
        change: { sgf: dataEl.sgf },
        index: i,
      });
    });
  },

  // If a user has never studied a problem, `problem_state` info is not in "joinedProblems" yet.
  initProblemState(context, lessonId) {
    context.state.joinedProblems.forEach((problem, index) => {
      if (
        problem.lessonId === lessonId &&
        (problem.state === null || problem.state === undefined)
      ) {
        context.commit('changeExistingStateValue', {
          table: 'joinedProblems',
          index,
          change: {
            ef: context.state.user_params[0].review_factor,
            isSelectedForStudy: 0,
            lastSeen: null,
            nextScheduledDate: null,
            state: 0,
            swapNumber: 0,
          },
        });
      }
    });
  },

  /**
   * Make 'stats' ready to use for today.
   * rows are sorted by SQL (serverside) so index 0 is most recent date.
   */
  async initStats({ state, commit, getters }, count = 14) {
    if (state.stats[0] && state.stats[0].today === lightFormat(new Date(), 'y-MM-dd')) return;
    await fetchDailyStats(count, commit, getters);
    if (state.stats.length === 0) makeUsersFirstStatsRecord(state, commit);
    if (state.stats[0].today === lightFormat(new Date(), 'y-MM-dd')) return;
    createNewStatsRecordForToday(state, commit);
  },

  /**
   * Make the final sorted and length-trimmed arrays that get used by the Problem.vue page:
   *    nlQueue (New and Learn)
   *    lrQueue (Learn and Review)
   * Set doneStack to empty
   * Prefetch sgfs for the first few problems in each queue
   *
   * Overview:  sort, trim, join, sort, trim, sgfFetch
   */
  makeNlAndLrQueues({ state, commit, dispatch, getters }) {
    const maxNew = state.user_params[0].max_new_problems_per_learn_button_press;
    const maxNL = state.user_params[0].max_problems_per_learn_button_press;
    const maxLR = state.user_params[0].max_problems_per_remember_button_press;
    const news = [...getters.myNewPs];
    const learns = [...getters.myDueLearnPs];
    const reviews = [...getters.myDueReviewPs];

    // add sort fields
    news.forEach((jp) => {
      jp.pastDueRatio = 0; // eslint-disable-line no-param-reassign
    });
    learns.forEach((jp) => {
      const overdueBy = differenceInMilliseconds(new Date(), parseISO(jp.nextScheduledDate));
      const scheduledInterval = differenceInMilliseconds(
        parseISO(jp.nextScheduledDate),
        parseISO(jp.lastSeen),
      );
      jp.pastDueRatio = overdueBy / scheduledInterval; // eslint-disable-line no-param-reassign
    });
    reviews.forEach((jp) => {
      const overdueBy = differenceInMilliseconds(new Date(), parseISO(jp.nextScheduledDate));
      const scheduledInterval = differenceInMilliseconds(
        parseISO(jp.nextScheduledDate),
        parseISO(jp.lastSeen),
      );
      jp.pastDueRatio = overdueBy / scheduledInterval; // eslint-disable-line no-param-reassign
    });

    // first sorts
    learns.sort((a, b) => b.pastDueRatio - a.pastDueRatio);
    reviews.sort((a, b) => b.pastDueRatio - a.pastDueRatio);

    // trimmedXXX
    const tNews = news.slice(0, maxNew);
    const tLearnNL = learns.slice(0, maxNL);
    const tLearnLR = learns.slice(0, maxLR);
    const tReviews = reviews.slice(0, maxLR);

    // joins
    const nlA = [...tLearnNL, ...tNews]; // note order
    const lrA = [...tLearnLR, ...tReviews];

    // for nlQueue, we want to study Learns first, then add News.
    const nl = nlA.slice(0, maxNL); // trim to length

    // second sort and trim for LR
    lrA.sort((a, b) => b.pastDueRatio - a.pastDueRatio);
    const lr = lrA.slice(0, maxLR);

    // save
    commit('setValue', {
      storeKey: 'nlQueue',
      storeValue: nl,
    });
    commit('setValue', {
      storeKey: 'lrQueue',
      storeValue: lr,
    });
    commit('setValue', {
      storeKey: 'doneStack',
      storeValue: [],
    });

    // prefetch sgfs
    dispatch('prefetchSgfs');
  },

  /**
   * QuickLook queue gets studied when user clicks QuickLook btn.
   * Queue is built when user visits LessonDetails page.
   * Presented in filename order.
   */
  makeQlQueue({ state, commit, dispatch }, lessonId) {
    const probs = state.joinedProblems.filter((jp) => {
      return jp.lessonId === lessonId && (jp.isPublished === 1 || state.showInactiveUnpublished);
    });
    probs.sort((a, b) => (a.filename < b.filename ? -1 : 1));
    commit('setValue', {
      storeKey: 'qlQueue',
      storeValue: probs,
    });
    commit('setValue', {
      storeKey: 'doneStack',
      storeValue: [],
    });
    dispatch('prefetchSgfs');
  },

  makeQlQueueFromProblemIds({ state, commit, dispatch }, problemIds) {
    const probs = [];
    problemIds.forEach((id) => {
      const prob = state.joinedProblems.find((jp) => jp.problemId === id);
      probs.push(prob);
    });
    probs.sort((a, b) => (a.filename < b.filename ? -1 : 1));
    commit('setValue', {
      storeKey: 'qlQueue',
      storeValue: probs,
    });
    commit('setValue', {
      storeKey: 'doneStack',
      storeValue: [],
    });
    dispatch('prefetchSgfs');
  },

  /**
   * When back button clicked, move problem backward from doneStack to original queue
   * (Back button only active in Quick Look mode at the moment, but I'm thinking to
   * add ability go go back one in TS modes)
   */
  moveProbFromDoneStackToQlQueue({ state, commit }) {
    // unshift problem into original queue
    const item = state.doneStack[state.doneStack.length - 1];
    commit('unshiftItem', {
      tableName: item.src,
      item: item.jp,
    });
    // remove from doneStack
    commit('deleteArrayItem', {
      tableName: 'doneStack',
      index: state.doneStack.length - 1,
    });
  },

  /**
   * Move a problem from a cycle queue to the doneStack
   * @param fromQueue 'nlQueue' | 'lrQueue' | 'qlQueue'
   */
  moveProbFromQueueToDoneStack({ state, commit }, fromQueue) {
    // push problem into doneStack
    commit('pushRecord', {
      tableName: 'doneStack',
      record: {
        src: fromQueue,
        jp: state[fromQueue][0],
      },
    });
    // remove problem from cycle queue
    commit('deleteArrayItem', {
      tableName: fromQueue,
      index: 0,
    });
  },

  /**
   * get (prefetch) sgf file for first N problems in each queue if they
   * aren't already present.
   */
  prefetchSgfs({ state, dispatch }) {
    const MAX_FETCH = 20;

    const que = []; // array of problemId's
    for (let i = 0; i < Math.min(MAX_FETCH, state.lrQueue.length); i += 1) {
      que.push(state.lrQueue[i].problemId);
    }
    for (let i = 0; i < Math.min(MAX_FETCH, state.nlQueue.length); i += 1) {
      que.push(state.nlQueue[i].problemId);
    }
    for (let i = 0; i < Math.min(MAX_FETCH, state.qlQueue.length); i += 1) {
      que.push(state.qlQueue[i].problemId);
    }
    // get the sgfs from server
    dispatch('fetchSgfs', que);
  },

  /**
   * problem studied, either TS or QL.
   * Async send info to server.  Updates stats, problem_view, problem_state tables.
   * Gets back either a new or updated stats record
   * Puts the stats record into $store.
   *
   * (By calculating daily stats on the server, the problem of a user studying
   * on two devices simultaneously screwing the stats is solved.)
   */
  async updateServerAndStatsForProblemStudied({ state, commit, getters }, payload) {
    const validatedPayload = addValidityHash(payload, state.user.us_id);
    const response = await getters.apiPut('trainingSystem/studied', { data: validatedPayload });
    const statsRecord = response.data;
    if (statsRecord.today === state.stats[0].today) {
      commit('updateTodaysStatsRecord', statsRecord);
      return;
    }
    // new stats day
    commit('unshiftStats', statsRecord);
    if (state.stats.length > 14) commit('popStats');
  },

  recordCustomDateSetInQuickLook({ state, commit, dispatch }, { currentProblem, dueDate }) {
    const payload = {
      buttonPressed: 'custom',
      nextScheduledDate: dueDate,
      dateViewed: lightFormat(new Date(), 'y-MM-dd'),
      ef: state.user_params[0].review_factor,
      isQuickLook: true,
      isSelectedForStudy: currentProblem.isSelectedForStudy,
      ip: state.ip,
      pb_id: currentProblem.problemId,
      prevProblemState: currentProblem.state,
      state: currentProblem.state,
      swapNumber: currentProblem.swapNumber + 1,
      timeSpent: null,
      whenViewed: formatISO(new Date()),
    };
    dispatch('updateServerAndStatsForProblemStudied', payload);
    commit('changeJpNextScheduledDate', { problemId: currentProblem.problemId, newDate: dueDate });
  },

  recordCustomDateSetInTrainingSystemMode(
    { state, dispatch, getters },
    { currentProblem, dueDate },
  ) {
    const payload = {
      buttonPressed: 'custom',
      dailyGoal: state.user_params[0].daily_goal,
      dateViewed: lightFormat(new Date(), 'y-MM-dd'),
      ef: currentProblem.ef,
      isQuickLook: false,
      isSelectedForStudy: currentProblem.isSelectedForStudy,
      ip: state.ip,
      nextScheduledDate: dueDate,
      pb_id: currentProblem.problemId,
      prevProblemState: currentProblem.state,
      state: 3,
      studyQueueLength: getters.myDueLearnPs.length + getters.myDueReviewPs.length,
      swapNumber: currentProblem.swapNumber + 1,
      timeSpent: null,
      whenViewed: formatISO(new Date()),
    };
    dispatch('updateServerAndStatsForProblemStudied', payload);

    console.log('more stuff goes here...');
  },

  recordQuickLookStudied({ state, dispatch }, { currentProblem, timeSpent }) {
    const payload = {
      dateViewed: lightFormat(new Date(), 'y-MM-dd'),
      ef: currentProblem.ef,
      isQuickLook: true,
      isSelectedForStudy: currentProblem.isSelectedForStudy,
      ip: state.ip,
      pb_id: currentProblem.problemId,
      prevProblemState: currentProblem.state,
      state: currentProblem.state,
      swapNumber: currentProblem.swapNumber + 1,
      timeSpent,
      whenViewed: formatISO(new Date()),
    };
    dispatch('updateServerAndStatsForProblemStudied', payload);
  },

  async removeProblemsFromBookByLessonId({ commit, getters }, lessonId) {
    // change in vuex state
    commit('removeProblemsFromBookByLessonId', lessonId);
    // queue change in database
    try {
      getters.apiPut('trainingSystem/remove-by-lesson', { data: { lessonId } });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while updating study book',
        message: e.message,
        variant: 'warning',
        duration: 2000,
      });
    }
  },
  async removeProblemsFromBookByProblemIds({ commit, getters }, problemIds) {
    await getters.apiPut('/trainingSystem/unbook-by-ids', {
      data: problemIds,
    });
    problemIds.forEach((id) => {
      commit('removeProblemFromBookByProblemId', id);
    });
  },
};

// ###############################################

async function fetchDailyStats(count, commit, getters) {
  try {
    const response = await getters.apiGet(`fetch/stats?count=${count}`);
    commit('initialTableLoad', response.data);
  } catch (e) {
    console.log(e);
    commit('pushFlash', {
      title: 'There was an error while fetching stats table:',
      message: e.message,
      variant: 'warning',
      duration: 2000,
    });
  }
}

function makeUsersFirstStatsRecord(state, commit) {
  commit('unshiftStats', {
    us_id: state.user.us_id,
    today: lightFormat(new Date(), 'y-MM-dd'),
    new_problems: 0,
    learn_problems: 0,
    review_problems: 0,
    quick_looks: 0,
    minutes_watched: 0,
    goal_reached: 0,
    emptied_study_queue: 0,
    days_in_a_row: 0,
    used_vacation_days: 0,
    points: 0,
    multiplier: 1,
  });
  commit('fanfareNotPlayedYetToday');
}

function createNewStatsRecordForToday(state, commit) {
  // previous values from index 0  (pX => previousX)
  const {
    today: pToday,
    goal_reached: pGoalReached,
    days_in_a_row: pDaysInARow,
    used_vacation_days: pUsedVacationDays,
  } = state.stats[0];
  let multiplier;

  // figure out vacation days, streak, multiplier
  const daysSinceLast = differenceInCalendarDays(new Date(), parseISO(pToday));
  let used_vacation_days = pUsedVacationDays;
  let days_in_a_row = pDaysInARow; // todays streak starts where last ended

  if (!pGoalReached) used_vacation_days += 1; // didn't make goal costs 1 v day.
  used_vacation_days += daysSinceLast - 1; // didn't study days cost v days.
  const earnedVacationDays = Math.floor((days_in_a_row + 4) / 7);
  if (earnedVacationDays < used_vacation_days) {
    // ran out of vacation days
    days_in_a_row = 0;
    used_vacation_days = 0;
  }

  if (days_in_a_row < 2) multiplier = 1;
  else if (days_in_a_row > 4) multiplier = 5;
  else multiplier = days_in_a_row;

  commit('unshiftStats', {
    us_id: state.user.us_id,
    today: lightFormat(new Date(), 'y-MM-dd'),
    new_problems: 0,
    learn_problems: 0,
    review_problems: 0,
    quick_looks: 0,
    minutes_watched: 0,
    goal_reached: 0,
    emptied_study_queue: 0,
    days_in_a_row,
    used_vacation_days,
    points: 0,
    multiplier,
  });
  commit('fanfareNotPlayedYetToday');
}
