module.exports = {
  apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:9876/api/',
  imageUrl: process.env.VUE_APP_IMAGE_URL || 'http://localhost:9876',
  lecturesUrl: process.env.VUE_APP_LECTURES_URL || 'http://localhost:9876/lectures/',
  newsImageUrl: process.env.VUE_APP_NEWS_IMAGE_URL || 'https://igs4-images.s3-us-west-2.amazonaws.com/',
  bookmarkUrl: process.env.VUE_APP_BOOKMARK_URL,
  BRAINTREE_TRANSACTION_FEE: 0.30,
  BRAINTREE_TRANSACTION_PCT: 2.9,
  HELP_VIDEO_1: 'https://www.youtube.com/embed/PqywwnSJmfM',
  HELP_VIDEO_2: 'https://www.youtube.com/embed/arzqxkU2km0',
  HELP_VIDEO_3: 'https://www.youtube.com/embed/LBspcwMcGEE',
  HELP_VIDEO_4: 'https://www.youtube.com/embed/yXyCHTddeqg',
};
