import { IVuexRootState } from "@/types";


export default {
  clearJoinedLessons(state: IVuexRootState) {
    state.joinedLessons.splice(0);
  },




}