/* eslint-disable import/prefer-default-export */
import sha1 from '@/pure/sha1';

/**
 * validity hash to send to server.  Ideally the us_id is not sent in the 
 * res.body, but in the jwt in the auth header.  So adding the us_id into 
 * the hash is a bit harder to unravel and prevents a user from being able
 * to affect a different user's data.  
 */
export function addValidityHash(obj: object, us_id: number): object {
  const toHash = { ...obj, us_id };
  const validity = sha1(JSON.stringify(toHash)).slice(14, 29);
  return { ...obj, validity };
}

