<script>
import LoginComponent from '@/components/LoginComponent';

export default {
  name: 'LandingHeader',
  components: { LoginComponent },
  data() {
    return {
      showLoginModal: false,
    };
  },
  computed: {
    smallScreen() {
      if (this.$store.state.windowWidth > 500) return false;
      return true;
    },
  },
};
</script>

<template>
  <div>
    <div class="page-header">
      <img class="page-header__stone" src="@/assets/images/stone_white_shadow.png" alt="" />
      <img src="@/assets/images/igs-white.png" alt="" class="page-header__school-name" />
      <button
        type="button"
        class="login-link btn btn-sm"
        :class="{ 'btn-link': smallScreen, higher: smallScreen, 'btn-outline-white': !smallScreen }"
        @click="showLoginModal = true"
      >
        Login
      </button>
    </div>

    <!-- start login modal -->
    <b-modal v-model="showLoginModal" hide-header-close hide-footer title="Login">
      <template #default>
        <login-component
          @pw-reset-clicked="showLoginModal = false"
          @register-clicked="showLoginModal = false"
          @failed-login="showLoginModal = false"
        />
      </template>
    </b-modal>
    <!-- end login modal -->
  </div>
</template>

<style lang="scss" scoped>
.login-link {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  font-size: 0.8rem;
}
.higher {
  top: 0;
}

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 20vw;
  overflow: hidden;
  background-color: $petal;
  &__stone {
    position: absolute;
    height: 22vw;
    width: 22vw;
    top: -3.6vw;
    left: -3.6vw;
  }
  &__school-name {
    position: absolute;
    object-fit: cover;
    height: auto;
    width: 60vw;
    top: 3vw;
    left: 25vw;
  }
}

@media (min-width: 530px) {
  .page-header {
    height: 18vw;
    &__stone {
      height: 20vw;
      width: 20vw;
      top: -3.3vw;
      left: -3.3vw;
    }
    &__school-name {
      width: 50vw;
      top: 3vw;
      left: 27vw;
    }
  }
}
@media (min-width: 800px) {
  .page-header {
    height: 16vw;
    &__stone {
      height: 18vw;
      width: 18vw;
      top: -3.1vw;
      left: -3.0vw;
    }
    &__school-name {
      width: 45vw;
      top: 4vw;
      left: 30vw;
    }
  }
}
@media (min-width: 1100px) {
  .page-header {
    height: 14vw;
    &__stone {
      height: 16vw;
      width: 16vw;
      top: -2.8vw;
      left: -2.7vw;
    }
    &__school-name {
      width: 40vw;
      top: 3vw;
      left: 31vw;
    }
  }
}
</style>
