import { IVuexRootState } from '@/types';

export default {
  deckIsPublic: (state: IVuexRootState) => (deckId: number) => {
    return state.joinedDecks.find((deck) => deck.deckId === deckId)!.isDeckPublic;
  },

  // returns us_id of deck's owner
  deckOwnerId: (state: IVuexRootState) => (deckId: number) => {
    return state.joinedDecks.find((jd) => jd.deckId === deckId)!.owner;
  },

  deckTitle: (state: IVuexRootState) => (deckId: number) => {
    return state.joinedDecks.find((deck) => deck.deckId === deckId)!.title;
  },

  getDeckById: (state: IVuexRootState) => (deckId: number) => {
    return state.joinedDecks.find(deck=>deck.deckId === deckId);
  },

  // returns a list of decks owned by logged in user
  myDecks(state: IVuexRootState) {
    const userId = state!.user!.us_id;
    return state.joinedDecks.filter((deck) => deck.owner === userId);
  },
};
