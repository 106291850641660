import Vue from 'vue';
import Router from 'vue-router';
import store from '@/vuex';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      name: 'aboutGJ',
      path: '/info/aboutGJ',
      component: () =>
        import(
          /* webpackChunkName: "about" */
          '@/views/info/AboutGJ.vue'
        ),
    },
    {
      name: 'deckDetails',
      path: '/deckDetails/:id',
      component: () =>
        import(
          /* webpackChunkName: "decks" */
          '@/views/decks/DeckDetails.vue'
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      name: 'decksDisplay',
      path: '/decks',
      component: () =>
        import(
          /* webpackChunkName: "decks" */
          '@/views/decks/DecksList.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'editDeck',
      path: '/edit-deck/:id',
      component: () =>
        import(
          /* webpackChunkName: "decks" */
          '@/views/decks/EditDeckPage.vue'
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      name: 'adminImageUpload',
      path: '/admin/image',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/ImageUpload.vue'
        ),
      meta: { requiresAuth: true, adminOnly: true },
    },
    {
      name: 'adminLecture',
      path: '/admin/lecture/:lessonId',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/AdminLecture.vue'
        ),
      props: true,
      meta: { requiresAuth: true, adminOnly: true },
    },
    {
      name: 'adminNewNews',
      path: '/admin/newNews',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/NewNews.vue'
        ),
      props: true,
      meta: { requiresAuth: true, adminOnly: true },
    },
    {
      name: 'adminModeration',
      path: '/admin/moderation',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Moderation.vue'
        ),
      meta: { requiresAuth: true, adminOnly: true },
    },
    {
      name: 'adminPayments',
      path: '/admin/payments',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/Payments.vue'
        ),
      meta: { requiresAuth: true, adminOnly: true },
    },
    {
      name: 'adminUsers',
      path: '/admin/users',
      component: () =>
        import(
          /* webpackChunkName: "admin" */
          '@/views/admin/UsersPage.vue'
        ),
      meta: { requiresAuth: true, adminOnly: true },
    },

    {
      name: 'bugReport',
      path: '/help/bugreport',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/BugReport.vue'
        ),
    },
    {
      name: 'contact',
      path: '/contact',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/info/Contact.vue'
        ),
    },
    {
      name: 'discuss-default',
      path: '/discuss/:pid',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "discuss" */
          '@/views/Discuss.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'discuss',
      path: '/discuss/:pid/:mode',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "discuss" */
          '@/views/Discuss.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'faq',
      path: '/help/faq',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/FaqPage.vue'
        ),
    },
    {
      name: 'forumRedirect',
      path: '/info/forum/:categoryId/:topicId/:postId',
      component: () =>
        import(
          /* webpackChunkName: "forum" */
          '@/views/forum/ForumRedirect.vue'
        ),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      name: 'forumPosts',
      path: '/info/forum/:categoryId/:topicId',
      component: () =>
        import(
          /* webpackChunkName: "forum" */
          '@/views/forum/ForumPosts.vue'
        ),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      name: 'forumTopics',
      path: '/info/forum/:categoryId',
      component: () =>
        import(
          /* webpackChunkName: "forum" */
          '@/views/forum/ForumTopics.vue'
        ),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      name: 'forumCategories',
      path: '/info/forum',
      component: () =>
        import(
          /* webpackChunkName: "forum" */
          '@/views/forum/ForumCategories.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'quickStart',
      path: '/help/quick-start',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/QuickStartHelp.vue'
        ),
    },
    {
      name: 'helpOwnProblems',
      path: '/help/own-problems',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/MakeOwnProblems.vue'
        ),
    },
    {
      name: 'home',
      path: '/',
      beforeEnter: (_to, _from, next) => {
        if (!store.getters.isLoggedIn) {
          next('/landing');
        }
        next();
      },
      component: () =>
        import(
          /* webpackChunkName: "home" */
          '@/views/Home.vue'
        ),
    },
    {
      name: 'collections',
      path: '/collections',
      component: () =>
        import(
          /* webpackChunkName: "info" */
          '@/views/search/ProblemCollections.vue'
        ),
    },
    {
      name: 'search',
      path: '/search',
      component: () =>
        import(
          /* webpackChunkName: "info" */
          '@/views/search/SearchLessonList.vue'
        ),
    },
    {
      name: 'landing',
      path: '/landing',
      beforeEnter: (_to, _from, next) => {
        if (store.getters.isLoggedIn) {
          next('/');
        }
        next();
      },
      component: () =>
        import(
          /* webpackChunkName: "landing" */
          '@/views/LandingPage.vue'
        ),
    },

    {
      name: 'lectureHelp',
      path: '/help/lecture',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/LectureHelp.vue'
        ),
    },
    {
      name: 'loginRedirect',
      path: '/login_redirect',
      component: () =>
        import(
          /* webpackChunkName: "login" */
          '@/views/user/LoginRedirect.vue'
        ),
    },
    {
      name: 'lecture',
      path: '/lecture/:lessId',
      component: () =>
        import(
          /* webpackChunkName: "lecture" */
          '@/views/Lecture.vue'
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      name: 'lessonDetails',
      path: '/lessonDetails/:lessonId',
      component: () =>
        import(
          /* webpackChunkName: "lessonDetails" */
          '@/views/LessonDetails.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'myAccount',
      path: '/user/myAccount',
      component: () =>
        import(
          /* webpackChunkName: "user" */
          '@/views/user/MyAccount.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'myGiftCerts',
      path: '/user/myGCs',
      component: () =>
        import(
          /* webpackChunkName: "myGiftCerts" */
          '@/views/user/MyGiftCerts.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'myLectHist',
      path: '/user/myLectHist',
      component: () =>
        import(
          /* webpackChunkName: "user" */
          '@/views/user/LectureHistory.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'myProfile',
      path: '/user/myProfile',
      component: () =>
        import(
          /* webpackChunkName: "user" */
          '@/views/user/MyProfile.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'GJsBlog',
      path: '/info/gj-blog',
      component: () =>
        import(
          /* webpackChunkName: "about" */
          '@/views/info/GJsBlog.vue'
        ),
    },
    {
      name: 'news',
      path: '/info/news',
      component: () =>
        import(
          /* webpackChunkName: "news" */
          '@/views/info/NewsPage.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'ownProblems',
      path: '/own_problems',
      component: () =>
        import(
          /* webpackChunkName: "ownProblems" */
          '@/views/OwnProblems.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'preferences',
      path: '/user/preferences',
      component: () =>
        import(
          /* webpackChunkName: "user" */
          '@/views/user/MyPrefs.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'passwordReset',
      path: '/pw-reset/:code',
      component: () =>
        import(
          /* webpackChunkName: "pwReset" */
          '@/views/PwReset.vue'
        ),
      props: true,
    },
    {
      name: 'PublicProfile',
      path: '/profile/:userId',
      component: () =>
        import(
          /* webpackChunkName: "user" */
          '@/views/Profile.vue'
        ),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      name: 'pvtLessonsHelp',
      path: '/help/pvt_lessons',
      component: () =>
        import(
          /* webpackChunkName: "about" */
          '@/views/help/PvtLessons.vue'
        ),
    },
    {
      name: 'registration',
      path: '/register',
      component: () =>
        import(
          /* webpackChunkName: "registration" */
          '@/components/RegistrationForm.vue'
        ),
    },
    {
      name: 'stats',
      path: '/user/stats',
      component: () =>
        import(
          /* webpackChunkName: "stats" */
          /* webpackPrefetch: true */
          '@/views/user/Stats.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'store',
      path: '/store',
      component: () =>
        import(
          /* webpackChunkName: "store" */
          '@/views/StorePage.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'purchaseGiftCertificate',
      path: '/store/gift-certificate',
      component: () =>
        import(
          /* webpackChunkName: "giftCert" */
          '@/views/store/PurchaseGiftCertificate.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'training',
      path: '/training/:pid/:mode',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "problem" */
          '@/views/ProblemPage.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'training-bookmark',
      path: '/training/:pid',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "problem" */
          '@/views/ProblemPage.vue'
        ),
      meta: { requiresAuth: true },
    },
    {
      name: 'tsHelp',
      path: '/help/ts',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/help/Ts.vue'
        ),
    },
    {
      name: 'unsubscribe',
      path: '/unsubscribe/:token',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "unsubscribe" */
          '@/views/Unsubscribe.vue'
        ),
    },
    {
      name: '404',
      path: '*',
      component: () =>
        import(
          /* webpackChunkName: "help" */
          '@/views/NotFound.vue'
        ),
    },
  ],
});
/**
 * Before each transition between routes, check if the token exists and redirect to login
 * if the token hasn't been fetched yet.
 */
router.beforeEach((to, _from, next) => {
  // in TsControlPanel an interval is set up and needs teardown when routing away
  if (store.state.oneMinuteIntervalID) {
    clearInterval(store.state.oneMinuteIntervalID);
    store.commit('setValue', { storeKey: 'oneMinuteIntervalID', storeValue: null });
  }

  if (to.meta.requiresAuth) {
    if (store.state.token.length > 0) {
      if (to.meta.adminOnly) {
        if (store.state.user.admin) {
          next();
        } else {
          console.log('You must be logged in as an admin to view that page');
          next('/login_redirect');
        }
      } else {
        next();
      }
    } else {
      store.commit('setValue', { storeKey: 'returnTo', storeValue: to });
      next('/login_redirect');
    }
  } else {
    next();
  }
});

global.router = router;
export default router;
