export default {


  /**
   * Get joinedLessons table.
   * @param {*} options: optional {
   *     include: lessonId -- return only one lesson record
   *     exclude: lessonId -- return all except one lesson record
   *   }
   */
  async getJoinedLessons(
    { state, getters, commit }: any,
    options?: { include?: number; exclude?: number },
  ) {
    if (state.joinedLessons.length > 1) return;
    let url = 'lessons';
    if (options && options.include) url = `lessons?include=${options.include}`;
    if (options && options.exclude) url = `lessons?exclude=${options.exclude}`;
    try {
      const response = await getters.apiGet(url);
      commit('appendRows', response.data);
    } catch (e) {
      const err = e as Error;
      console.log(err);
      commit('pushFlash', {
        title: 'There was an error while fetching lesson lists:',
        message: err.message,
        variant: 'warning',
        duration: 5000,
      });
    }
  },
};
