import { IVuexRootState } from '@/types';
import { addDays, isAfter, isFuture, parseISO } from 'date-fns';

export default {

  freeTrialDate(state: IVuexRootState) {
    if (!state.user) return undefined;
    if (!state.user.got_free_trial) return null;
    return parseISO(state.user.got_free_trial);
  },

  freeTrialState(state: IVuexRootState) {
    if (!state.user) return undefined;
    if (state.user.got_free_trial === null) return 'Available';
    if (isFuture(addDays(parseISO(state.user.got_free_trial), 1))) return 'Valid';
    return 'Completed';
  },

  isAdmin: (state: IVuexRootState) => (state.user && state.user.admin),
  isTeacher: (state: IVuexRootState) => (state.user && state.user.teacher),
  isTranslator: (state: IVuexRootState) => (state.user && state.user.translator),

  // for transition, ANY valid membership authorizes Training System
  isAuthForTrainingSystem(state: IVuexRootState, getters: any) {
    if (!getters.isLoggedIn) return false;
    if (getters.isAdmin) return true;
    if (getters.isTeacher) return true;
    if (getters.freeTrialState === 'Valid') return true;
    if (state.membership.length === 0) return false;
    if (state.membership.some((mbr) => isAfter(addDays(parseISO(mbr.validity), 2), new Date()) && mbr.iv_id)) return true;
    return false;
  },

  isLoggedIn: (state: IVuexRootState) => !!state.token,

  numLectureCredits(state: IVuexRootState, getters: any) {
    if (!getters.isLoggedIn) return 0;
    if (!state.user || !state.user.lect_credits) return 0;
    return state.user.lect_credits;
  },

  /* eslint-disable no-bitwise */
  playGoalAchievedSound: (state: IVuexRootState) => {
    if (!state.user || !state.user.sounds) return false;
    return (state.user.sounds & 0b100) === 0b100;
  },
  playRightWrongSounds: (state: IVuexRootState) => {
    if (!state.user || !state.user.sounds) return false;
    return (state.user.sounds & 0b010) === 0b010;
  },
  playStoneClicks: (state: IVuexRootState) => {
    if (!state.user || !state.user.sounds) return false;
    return (state.user.sounds & 0b001) === 0b001;
  },
  /* eslint-enable no-bitwise */

  userId: (state: IVuexRootState) => state.user ? state.user.us_id : undefined




}
