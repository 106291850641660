import axios, { AxiosRequestConfig, AxiosStatic, Method } from 'axios';
import { IVuexRootState } from '@/types';
import config from '@/config';

export default {
  /**
   * dependency injection, partial execution for Axios
   * includes auth header if user is logged in
   */
  api:
    (state: IVuexRootState) =>
    (axios_: AxiosStatic) =>
    (baseURL: string) =>
    (method: Method) =>
    (url: string, addlOptions: Object | null = null) => {
      let options: AxiosRequestConfig = { method, baseURL, url };
      if (state.token) options.headers = { Authorization: `Bearer ${state.token}` };
      if (addlOptions) options = { ...options, ...addlOptions };
      return axios_(options);
    },

  /**
   * partial function remaining parameters:    (url [, addlOptions])
   * url looks like '/fetch/sgfs'
   * addlOptions looks like { params: {limit: 5}}
   * addlOptions looks like { data: {limit: 5}}
   */
  apiGet: (_state: IVuexRootState, getters: any) => getters.api(axios)(config.apiUrl)('get'),

  apiPut: (_state: IVuexRootState, getters: any) => getters.api(axios)(config.apiUrl)('put'),

  apiPost: (_state: IVuexRootState, getters: any) => getters.api(axios)(config.apiUrl)('post'),

  apiDelete: (_state: IVuexRootState, getters: any) => getters.api(axios)(config.apiUrl)('delete'),

  // different baseURL for lecture audio, json and sgf files
  apiGetLectureComponent: (_state: IVuexRootState, getters: any) =>
    getters.api(axios)(config.lecturesUrl)('get'),

};
