import { addValidityHash } from '@/pure/auth';


export default {

  /**
   * fetch lecture json file and save in store.
   */
  async fetchLectureJsonFile({commit, getters}, lessonId){
    try {
      const response = await getters.apiGetLectureComponent( `${lessonId}/${lessonId}.json` );

      // shove an extra node in at 1 second same as zero
      // so the initial board position will load
      const zero = response.data[0];
      response.data.splice(1, 0, { activate: zero.activate, time: 1 });
      // save in store for Lecture page
      commit('lessonPrefetchSet', {
        json: response.data,
        lessonId,
      });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching the lecture json file:',
        message: e.message,
        variant: 'warning',
        duration: 3000,
      });
    }
  },

  /**
   * fetch lecture sgf file and save in store
   */
  async fetchLectureSgfFile({commit, getters}, lessonId){
    try {
      const response = await getters.apiGetLectureComponent(`${lessonId}/${lessonId}.sgf`);
      // save in store for Lecture page
      commit('lessonPrefetchSet', {
        sgf: response.data,
        lessonId,
      });
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while fetching the lecture sgf file:',
        message: e.message,
        variant: 'warning',
        duration: 3000,
      });
    }
  },

  async lectureMinutesWatched({state, commit, getters}, payload){
    const validatedPayload = addValidityHash(payload, state.user.us_id);
    const response = await getters.apiPut('lessons/minutes-watched', { data: validatedPayload });
    const statsRecord = response.data;
    if (statsRecord.today === state.stats[0].today){
      commit('changeExistingStateValue', {
        table: 'stats',
        change: statsRecord,
        index: 0,
      })
      return;
    }
    // new stats day
    commit('unshiftStats', statsRecord);
    if (state.stats.length > 14) commit('popStats');
  },








}