/* eslint-disable no-param-reassign */
import { IVuexRootState } from '@/types';

export default {
  addProblemsToBookByLessonId(state: IVuexRootState, lessonId: number) {
    state.joinedProblems.forEach((pb) => {
      if (pb.lessonId === lessonId) pb.isSelectedForStudy = 1;
    });
    state.joinedLessons[
      state.joinedLessons.findIndex((ls) => ls.lessonId === lessonId)
    ].problemSetEnabled = 1;
  },

  addProblemToBookByProblemId(state: IVuexRootState, problemId: number) {
    const jp = state.joinedProblems.find((p) => p.problemId === problemId);
    jp!.isSelectedForStudy = 1;
    if (jp!.state === null) {
      jp!.ef = state.user_params[0].review_factor;
      jp!.isSelectedForStudy = 1;
      jp!.state = 0;
      jp!.swapNumber = 0;
    }
  },

  removeProblemsFromBookByLessonId(state: IVuexRootState, lessonId: number) {
    state.joinedProblems.forEach((pb) => {
      if (pb.lessonId === lessonId) pb.isSelectedForStudy = 0;
    });
    state.joinedLessons[
      state.joinedLessons.findIndex((ls) => ls.lessonId === lessonId)
    ].problemSetEnabled = 0;
  },

  removeProblemFromBookByProblemId(state: IVuexRootState, problemId: number){
    const jp = state.joinedProblems.find((p) => p.problemId === problemId);
    jp!.isSelectedForStudy = 0;
  },
};
