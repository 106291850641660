import { addMinutes, isPast } from 'date-fns';

export default {
  async fetchNews({ state, commit, getters }: any): Promise<void> {
    if (state.newsFetchedAt && isPast(addMinutes(state.newsFetchedAt, 10))) return
    const NUM_TO_GET = 10;
    const response = await getters.apiGet(`fetch/news?qty=${NUM_TO_GET}`);
    commit('saveNews', response.data )
  },
};
