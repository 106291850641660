/* eslint-disable no-param-reassign */



export default {

  deleteAllMemberships(state) {
    state.membership = [];
  },

  saveMemberships(state, membershipsArray) {
    state.membership = membershipsArray
  }



}