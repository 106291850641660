
<script>
import sha1 from '@/pure/sha1';

export default {
  name: 'LoginComponent',
  data() {
    return {
      username: '',
      password: '',
      rememberUsername: false,
      keepLoggedin: false,
      forgotPassword: false,
    };
  },
  computed: {
    // isLoggedIn() {
    //   return this.$store.getters.isLoggedIn;
    // },
    sha1() {
      return sha1(this.password);
    },
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, 'rememberUsername')) {
      if (localStorage.rememberUsername) {
        this.rememberUsername = true;
        this.username = localStorage.username;
      } else {
        this.rememberUserename = false;
        this.username = '';
      }
    }
  },
  methods: {
    async doLogin() {
      const isSuccessfulLogin = await this.$store.dispatch('doLogin', {
        username: this.username,
        sha1: this.sha1,
        rememberUsername: this.rememberUsername,
        keepLoggedin: this.keepLoggedin,
      });
      this.password = '';

      const { returnTo } = this.$store.state;

      if (!isSuccessfulLogin) {
        this.$emit('failed-login');
        if (this.$route.path !== '/landing') return this.$router.push('/landing');
        return false;
      }

      if (returnTo) {
        this.$store.commit('setValue', { storeKey: 'returnTo', storeValue: null });
        return this.$router.push(returnTo);
      }
      if (this.$route.path !== '/') this.$router.push('/');
      return true;
    },

    doPasswordReset() {
      this.$store.commit('pushFlash', {
        title: 'Password reset',
        message:
          'If your email exists in our database, you will receive a password recovery link at your email address in a few minutes.',
        variant: 'primary',
        duration: 10000,
      });
      // api call
      this.$store.dispatch('putPasswordReset', this.username);
      this.$emit('pw-reset-clicked'); // so parent can close login modal if necessary
    },
    newUserClick() {
      if (this.$route.path !== '/register') this.$router.push('/register');
      this.$emit('register-clicked');
    },
  },
};
</script>

<template>
  <div>
    <form>
      <div class="form-group flush-bottom">
        <label for="username">Username or email address</label>
        <input
          ref="username"
          v-model="username"
          required
          class="form-control"
          type="text"
          placeholder="username or email"
        />
      </div>

      <div v-if="!forgotPassword" class="form-check-group flush-top">
        <input id="remember-username" v-model="rememberUsername" type="checkbox" />
        <label for="remember-username">Remember username</label>
      </div>

      <div v-if="!forgotPassword" class="form-group flush-bottom">
        <label for="password">Password</label>
        <input
          id="password"
          v-model="password"
          required
          type="password"
          class="form-control"
          placeholder="password"
          @keypress.enter="doLogin"
        />
      </div>

      <div v-if="forgotPassword" class="explanation">
        An email with a password reset link will be sent to the email address connected with your
        account. If you no longer have access to this email, you can request help at
        feedback@internetgoschool.com.
      </div>

      <button
        v-if="!forgotPassword"
        class="btn btn-link flush-left"
        type="button"
        @click="forgotPassword = true"
      >
        Forgot Password
      </button>

      <div v-if="!forgotPassword" class="form-check-group">
        <input id="keep-logged-in" v-model="keepLoggedin" type="checkbox" />
        <label for="keep-logged-in">Keep me logged in</label>
      </div>
    </form>
    <div class="button-area">
      <button v-if="!forgotPassword" type="button" class="btn btn-link" @click="newUserClick">
        New user?
      </button>
      <button v-if="!forgotPassword" class="btn btn-success mx-4" @click="doLogin">Login</button>
      <button v-if="forgotPassword" type="button" class="btn btn-success" @click="doPasswordReset">
        Send password-reset email
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flush-left {
  padding-left: 0.25rem;
}
.flush-bottom {
  margin-bottom: 0;
}
.flush-top {
  margin-top: 0.25rem;
}
.button-area {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.explanation {
  margin: 2rem;
}
</style>