/* eslint-disable no-param-reassign */
import { IJoinedDeck, IVuexRootState } from "@/types";


export default {

  pushDeck(state: IVuexRootState, deck: IJoinedDeck ): void {
    state.joinedDecks.push(deck);
  },

  replaceDeck(state: IVuexRootState, deck: IJoinedDeck): void {
    const index = state.joinedDecks.findIndex(jd=>jd.deckId === deck.deckId);
    if (index === -1) {
      console.log('deck not found in vuex in "replaceDeck" mutation');
      return
    }
    state.joinedDecks[index] = deck;
  },

  saveDecks(state: IVuexRootState, decks: IJoinedDeck[]): void {
    state.joinedDecks = decks;
  },

  setDeckFetchTime(state: IVuexRootState): void {
    state.decksFetchedAt = new Date();
  },


}
