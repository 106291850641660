import { IJoinedProblem } from '@/types';
import router from '@/router';

interface NewProblem {
  problemId: number;
  filename: string;
  sgfText: string;
}

export default {
  // delete cards from decks
  async deleteCards({ state, commit, getters }: any, problemIds: number[]) {
    await getters.apiDelete('/trainingSystem/cards', { data: problemIds });
    problemIds.forEach((pid) => {
      commit('deleteOneJoinedProblem', pid);
    });
  },

  // --
  async fetchJoinedProblems({ state, commit, getters }: any) {
    if (state.joinedProblems.length > 1) return;
    try {
      const response = await getters.apiGet('trainingSystem/problems');
      commit('loadJoinedProblems', response.data);
    } catch (e) {
      const err = e as Error;
      console.log(err);
      commit('pushFlash', {
        title: 'There was an error while fetching Training System problems:',
        message: err.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  // Get just one, include sgf
  async fetchOneJoinedProblem({ state, commit, getters }: any, pb_id: number): Promise<void> {
    if (state.joinedProblems.length > 1) return;
    const url = `trainingSystem/problems?include=${pb_id}`;
    try {
      const response = await getters.apiGet(url);
      commit('loadJoinedProblems', response.data);
    } catch (e) {
      const err = e as Error;
      console.log(err);
      commit('pushFlash', {
        title: 'There was an error while fetching a single Training System problem:',
        message: err.message,
        variant: 'warning',
        duration: 4000,
      });
    }
  },

  // get sgf, add to joinedProblems record
  async fetchAndSaveOneSgf({ state, commit, getters }: any, problemId: number) {
    if (!getters.isAuthForTrainingSystem) return;
    try {
      const response = await getters.apiGet(`fetch/sgfs?ids=${problemId}`);
      const index = state.joinedProblems.findIndex(
        (jp: IJoinedProblem) => jp.problemId === problemId,
      );
      const { sgf } = response.data[0];
      commit('changeExistingStateValue', {
        table: 'joinedProblems',
        change: { sgf },
        index,
      });
    } catch (e) {
      const err = e as Error;
      commit('pushFlash', {
        title: 'Error fetching sgf file',
        message: `${err.message} -- returning to Home page`,
        variant: 'danger',
        duration: 5000,
      });
      router.push('/');
    }
  },

  async moveCardsToNewDeck(
    { state, commit, getters }: any,
    { problemIds, newDeckId }: { problemIds: number[]; newDeckId: number },
  ) {
    try {
      await getters.apiPut('/trainingSystem/move-cards', {
        data: {
          problemIds,
          destinationDeckId: newDeckId,
        },
      });
      commit('changeJpsDeckIds', { problemIds, newDeckId });
    } catch (e) {
      const err = e as Error;
      commit('pushFlash', {
        title: 'Error at server',
        message: `${err.message} -- couldn't move cards to new deck`,
        variant: 'danger',
        duration: 5000,
      });
    }
  },

  async renameProblems(
    { state, getters, commit }: any,
    changes: { problemId: number; newFilename: string }[],
  ) {
    await getters.apiPut('/trainingSystem/rename-problems', { data: changes });
    changes.forEach((change) => commit('changeJpFilename', change));
  },
};
