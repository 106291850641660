/* eslint-disable no-use-before-define */

interface PayloadM {
  problemId: number;
  tagText: string;
}

export default {
  async addProblemToCollection({ commit, getters }: any, payload: PayloadM): Promise<void> {
    const { problemId, tagText } = payload;
    await getters.apiPut('/fetch/tag_add', { data: payload });
    const tagId = getters.findTagIdFromTagText(tagText);
    commit('addProblemToCollection', { problemId, tagId, tagText });
  },

  async deleteCollections({ commit, getters }: any, toDelete: string[]): Promise<void> {
    try {
      await getters.apiPut('/fetch/tag_delete', { data: { toDelete } });
      toDelete.forEach(tagText => {
        commit('deleteProblemsFromJoinedTags', tagText);
      });
      toDelete.forEach(tagText => {
        commit('deleteTagFromMyTags', tagText);
      });
      toDelete.forEach(tagText => {
        commit('deleteTagFromTags', tagText);
      });
    } catch (err) {
      console.log(err);
      commit('pushFlash', {
        title: 'Network error',
        message: `An error occured while communicating with the server:
        ${err}`,
        variant: 'danger'
      });
    }
  },

  // tagged problems from tag2pb table
  async fetchJoinedTags({ state, commit, getters }: any): Promise<void> {
    if (state.joinedTags.length) return;
    const response = await getters.apiGet('fetch/joined_tags');
    commit('loadJoinedTags', response.data);
  },

  // "MyTags" are tags this user has defined, from tag2us table
  async fetchMyTags({ state, commit, getters }: any): Promise<void> {
    if (state.myTags.length) return;
    const response = await getters.apiGet('fetch/my_tags');
    commit('loadMyTags', response.data);
  },

  // all tags in the tag table
  async fetchTags({ state, commit, getters }: any): Promise<void> {
    if (state.tag.length) return;
    const response = await getters.apiGet('fetch/tags');
    commit('loadTags', response.data);
  },

  async makeNewCollection({ commit, getters }: any, payload: PayloadM): Promise<void> {
    const { problemId, tagText } = payload;
    try {
      const tagId = await getters.apiPut('/fetch/tag_new', { data: payload });
      commit('addNewTagToTag', { tagId, tagText });
      commit('addNewTagToMyTags', tagText);
      if (problemId) commit('addProblemToCollection', { problemId, tagId, tagText });
    } catch (err) {
      console.log(err);
      commit('pushFlash', {
        title: 'Network error',
        message: `An error occured while communicating with the server:
        ${err}`,
        variant: 'danger'
      });
    }
  },

  async removeProblemFromCollection({ commit, getters }: any, payload: PayloadM): Promise<void> {
    const { problemId, tagText } = payload;
    await getters.apiPut('/fetch/tag_remove', { data: payload });
    const tagId = getters.findTagIdFromTagText(tagText);
    commit('removeProblemFromCollection', { problemId, tagId, tagText });
  }
};
