


export default {

  async getPaymentToken({ commit, getters }) {
    try {
      const response = await getters.apiGet('payment/client_token');
      return response.data;  // { clientToken, googleMerchantId }
    } catch (e) {
      console.log(e);
      commit('pushFlash', {
        title: 'There was an error while initializing purchase feature.',
        message: e.message,
        variant: 'warning',
        duration: 4000,
      });
      return { success: false };
    }
  },

  async paymentCredit({ state, commit, getters }, payload) {
    payload.ip = state.ip; // eslint-disable-line no-param-reassign
    payload.country = state.countryCode; // eslint-disable-line no-param-reassign
    try {
      const result = await getters.apiPost('payment/use_credit', { data: payload });
      return { ...result.data, success: true };
    } catch (e) {
      console.error(JSON.stringify(e.response));
      commit('pushFlash', {
        title: 'There was an error processing store credit payment.',
        message: e.response.data.message,
        variant: 'danger',
        duration: 30000,
      });
      return { status: e.response.status, success: false };
    }
  },

  async paymentCheckout({ state, commit, getters }, payload) {
    payload.ip = state.ip; // eslint-disable-line no-param-reassign
    payload.country = state.countryCode; // eslint-disable-line no-param-reassign
    try {
      const result = await getters.apiPost('payment/checkout', { data: payload });
      return { ...result.data, status: result.status };
    } catch (e) {
      // Status code 428 from the server indicates that the user will need to
      // confirm (additional UI) that the subscription will happen immediately. Do
      // not flash this error as it's an expected error state.
      if (e.response.status !== 428) {
        console.error(JSON.stringify(e.response));
        commit('pushFlash', {
          title: 'There was an error processing the payment.',
          message: e.response.data.message,
          variant: 'danger',
          duration: 30000,
        });
      }
      return { status: e.response.status, success: false };
    }
  },


  async paymentGiftCertificate({ state, commit, getters }, payload) {
    payload.ip = state.ip; // eslint-disable-line no-param-reassign
    payload.country = state.countryCode; // eslint-disable-line no-param-reassign
    try {
      const result = await getters.apiPost('payment/gift_certificate', { data: payload });
      return { ...result.data, status: result.status };
    } catch (e) {
      console.error(JSON.stringify(e.response));
      commit('pushFlash', {
        title: 'There was an error processing the payment.',
        message: e.response.data.message,
        variant: 'danger',
        duration: 30000,
      });
      return { status: e.response.status, success: false };
    }
  },

  async paymentGiftCertificateRedeem({ commit, getters }, payload) {
    if (!payload.code) {
      commit('pushFlash', {
        title: 'Gift certificate redeem error.',
        message: 'No code entered.',
        variant: 'danger',
        duration: 3000,
      });
    }
    try {
      const result = await getters.apiGet(`payment/redeem/${payload.code}`)
      const { balance, amount, message } = result.data;
      return { balance, amount, message, status: result.status, success: true };
    } catch (e) {
      commit('pushFlash', {
        title: 'Gift certificate redeem error.',
        message: e.response.data.message,
        variant: 'danger',
        duration: 30000,
      });
      return { status: e.response.status, success: false };
    }
  },

  async cancelAutopaySubscription({ commit, getters }) {
    try {
      const result = await getters.apiGet('payment/cancel');
      return { ...result.data, success: true };
    } catch (e) {
      console.error(JSON.stringify(e.response));
      commit('pushFlash', {
        title: 'There was an error cancelling your subscription, contact administrator.',
        message: e.response.data.message,
        variant: 'danger',
        duration: 30000,
      });
    }
    return { success: false };
  },


  /**
   * reduce user's balance by one euro
   * calling code should have already checked that a credit exists.
   */
   subtractOneCredit({ state, commit }) {
    commit('changeExistingStateValue', {
      table: 'user',
      change: { balance: state.user.balance - 1 },
    });
  },  

  /**
   * reduce the number of lecture_credits by one
   */
   subtractOneLectureCredit({ state, commit }) {
    commit('changeExistingStateValue', {
      table: 'user',
      change: { lect_credits: state.user.lect_credits - 1 },
    });
  },






}