/* eslint-disable no-param-reassign */
import { addDays, addMonths, formatISO, isAfter, parseISO } from 'date-fns';
import axios from 'axios';

export default {
	zeroOutTables({ commit }) {
		commit('setValue', { storeKey: 'token', storeValue: '' });

		commit('setValue', { storeKey: 'donation', storeValue: [] });
		commit('setValue', { storeKey: 'enabled_subtitle', storeValue: [] });
		commit('setValue', { storeKey: 'gift_certificate', storeValue: [] });
		commit('setValue', { storeKey: 'invoice', storeValue: [] });
		commit('setValue', { storeKey: 'joinedLessons', storeValue: [] });
		commit('setValue', { storeKey: 'joinedProblems', storeValue: [] });
		commit('setValue', { storeKey: 'joinedViews', storeValue: [] });
		commit('setValue', { storeKey: 'membership', storeValue: [] });
		commit('setValue', { storeKey: 'news', storeValue: [] });
		commit('setValue', { storeKey: 'stats', storeValue: [] });
		commit('setValue', { storeKey: 'subscription', storeValue: [] });
		commit('setValue', { storeKey: 'subtitle', storeValue: [] });
		commit('setValue', { storeKey: 'user', storeValue: {} });
		commit('setValue', { storeKey: 'user_params', storeValue: [] });
		commit('setValue', { storeKey: 'view_ticket', storeValue: [] });

		commit('setValue', { storeKey: 'tag', storeValue: [] });
		commit('setValue', { storeKey: 'joinedTags', storeValue: [] });
		commit('setValue', { storeKey: 'myTags', storeValue: [] });

		commit('setValue', { storeKey: 'topic', storeValue: [] });
		commit('setValue', { storeKey: 'post', storeValue: [] });

		commit('setValue', { storeKey: 'nlQueue', storeValue: [] });
		commit('setValue', { storeKey: 'lrQueue', storeValue: [] });
		commit('setValue', { storeKey: 'qlQueue', storeValue: [] });
		commit('setValue', { storeKey: 'doneStack', storeValue: [] });

		commit('setValue', { storeKey: 'difficultySlider', storeValue: [ 1, 11 ] });
		commit('setValue', { storeKey: 'hideWatchedLectures', storeValue: false });
		commit('setValue', {
			storeKey: 'treeSelections',
			storeValue: {
				tree1: null,
				tree2: null,
				tree3: null
			}
		});

		commit('setValue', { storeKey: 'homepageProgBarPrevVal', storeValue: 0 });
		commit('setValue', { storeKey: 'homepageFanfarePlayed', storeValue: null });

		commit('setValue', { storeKey: 'prefetchSgf', storeValue: '' });
		commit('setValue', { storeKey: 'prefetchJson', storeValue: [] });
		commit('setValue', { storeKey: 'prefetchLessonId', storeValue: 0 });
		commit('setValue', { storeKey: 'lectureToken', storeValue: '' });

		commit('setValue', { storeKey: 'returnToUrl', storeValue: '/' });
		commit('setValue', { storeKey: 'ip', storeValue: '' });
		commit('setValue', { storeKey: 'countryCode', storeValue: '' });
		commit('setValue', { storeKey: 'city', storeValue: '' });

		commit('setValue', { storeKey: 'showInactiveUnpublished', storeValue: false });

		sessionStorage.removeItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		localStorage.removeItem('membership');
		localStorage.removeItem('view_ticket');
	},

	/**
   * returns Promise: true if auth, else false
   */
	async isAuthForTs({ state, getters, dispatch }) {
		if (getters.isAuthForTrainingSystem) return true;
		if (!getters.isLoggedIn) return false;
		const memberships = await dispatch('getMemberships');
		dispatch('replaceMemberships', memberships);
		return getters.isAuthForTrainingSystem;
	},

	/**
   * determine IF and HOW a user is authorized to watch a lecture
   * in preference order.
   *
   * NOTE:  for purposes of the transition from the old site to the new,
   * users holding a subscription with mb_id=2 will be auth for lectures too.
   * (this is a gift to them and avoids us having to figure out messy prorates).
   */
	async authForLectures({ state, getters, dispatch }, lessonId) {
		const { admin, author, teacher, translator } = state.user;
		if (!getters.isLoggedIn) return 'notAuthorized';
		if (state.user.got_free_trial && isAfter(addDays(parseISO(state.user.got_free_trial), 1), new Date()))
			return 'freeTrial';
		// privileged status?
		if (admin || author || translator || teacher) return 'byStatus';
		// free lecture?
		const { pricingId } = state.joinedLessons.find((jl) => jl.lessonId === lessonId);
		if (pricingId === 2) return 'freeLecture';
		// valid membership?
		if (state.membership.some((mbr) => mbr.iv_id && isAfter(addDays(parseISO(mbr.validity), 2), new Date())))
			return 'byMembership';
		// purchased within last 30 days?
		if (state.view_ticket.some((vt) => vt.ls_id === lessonId && isAfter(parseISO(vt.up_to), new Date())))
			return 'byTicket';
		// check if there is a more recent membership on server
		const memberships = await dispatch('getMemberships');
		dispatch('replaceMemberships', memberships);
		if (state.membership.some((mbr) => mbr.iv_id && isAfter(addDays(parseISO(mbr.validity), 2), new Date())))
			return 'byMembership';
		// lecture credits?
		if (getters.numLectureCredits > 0) return 'byLectureCredit';
		// cash balances?
		if (state.user.balance > 0) return 'byBalance';
		// none of those?
		return 'notAuthorized';
	},

	doLogin: async ({ dispatch, commit, getters }, { username, sha1, rememberUsername, keepLoggedin }) => {
		dispatch('zeroOutTables');
		commit('pushFlash', {
			title: 'Login',
			variant: 'info',
			message: 'Login in progress',
			duration: 1000
		});
		try {
			const response = await getters.apiPut('auth/login', {
				data: {
					username,
					sha1
				}
			});
			// response.data = { token: xxx, tablesArray: [...] }
			// tablesArray looks like: [ {tableName: "xxx", rows: [...]}, {tableName: "yyy", rows: [...]}, ... ]

			// save token to state variable
			commit('setValue', {
				storeKey: 'token',
				storeValue: response.data.token
			});

			if (rememberUsername) {
				// rememberUsername checkbox is checked
				localStorage.setItem('rememberUsername', true);
				localStorage.setItem('username', username);
			} else {
				// rememberUsername checkbox is not checked
				localStorage.setItem('rememberUsername', false);
				localStorage.setItem('username', '');
			}

			if (keepLoggedin) {
				// "keep me logged in" is checked => store token (and more) in localStorage
				localStorage.setItem('token', response.data.token);
			}

			dispatch('tablesArrayToStoreAndLocalStorage', response.data.tablesArray);

			commit('pushFlash', {
				title: 'Login',
				message: 'Login successful',
				duration: 1000
			});

			dispatch('getGeoLocation');
			return true; // true = successful login
		} catch (err) {
			console.log(err);
			commit('pushFlash', {
				title: 'Login Error',
				message: 'Login failed, please try again',
				variant: 'info',
				duration: 3000
			});
			return false;
		}
	},

	doLogout(context) {
		context.dispatch('zeroOutTables');
		if (router.currentRoute.path !== '/landing') router.push('/landing'); // eslint-disable-line no-undef
	},

	doRegistration: async ({ dispatch, commit, getters }, payload) => {
		try {
			const response = await getters.apiPost('user/register', { data: { registrationData: payload } });

			// log our new user in
			localStorage.setItem('rememberUsername', false);
			localStorage.setItem('username', '');

			dispatch('tablesArrayToStoreAndLocalStorage', response.data.tablesArray);

			commit('setValue', {
				storeKey: 'token',
				storeValue: response.data.token
			});
		} catch (err) {
			commit('pushFlash', {
				title: 'There was an error during registration:',
				message: err.message,
				variant: 'danger',
				duration: 8000
			});
			dispatch('doLogout');
		}
	},

	/**
   * get IP address and hopefully also the country code
   * saves to $store and to database
   */
	async getGeoLocation({ state, commit, getters }) {
		let ip;
		let countryCode;

		try {
			const cloudResp = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
			const cArray = cloudResp.data.split('\n');
			const ipEl = cArray.find((el) => el.startsWith('ip'));
			if (!ipEl) throw new Error('no ip line');
			[ , ip ] = ipEl.split('=');
			const ccEl = cArray.find((el) => el.startsWith('loc'));
			if (ccEl) {
				[ , countryCode ] = ccEl.split('=');
			}
		} catch (e) {
			console.log('cloudflare', e);
			try {
				const ipifyRes = await axios.get('https://api.ipify.org?format=json');
				ip = ipifyRes.data;
			} catch (er) {
				console.log('ipify: ', er);
			}
		}

		if (ip && state.ip !== ip) {
			commit('setValue', {
				storeKey: 'ip',
				storeValue: ip
			});
		}

		if (countryCode && !state.country) {
			commit('setValue', {
				storeKey: 'countryCode',
				storeValue: countryCode
			});
			getters.apiPut('user/filters', { data: { country: countryCode } });
		}
	},

	/**
   * gets Lecture Token from server
   */
	async getLectureToken({ commit, getters }, lessonId) {
		try {
			const response = await getters.apiGet(`lessons/watch-auth?lessonId=${lessonId}`);
			return response.data.lectureToken;
		} catch (e) {
			console.log('In getLectureToken: ', e);
			commit('pushFlash', {
				title: 'There was an error while fetching lecture:',
				message: e.message,
				variant: 'warning',
				duration: 2000
			});
			return null;
		}
	},

	newViewTicket({ state, commit }, lessonId) {
		commit('pushRecord', {
			tableName: 'view_ticket',
			record: {
				ls_id: lessonId,
				us_id: state.user.us_id,
				up_to: formatISO(addMonths(new Date(), 1))
			}
		});
	},

	async refreshToken({ getters, commit }) {
		// refresh token is due - get a new one and put it where it belongs
		let response;
		try {
			response = await getters.apiPut('auth/refresh');
		} catch (e) {
			console.log(`Token refresh failed.  Message:  ${e}`);
			return;
		}
		commit('setValue', {
			storeKey: 'token',
			storeValue: response.data.newToken
		});
		if (localStorage.token) localStorage.token = response.data.newToken;
	},

	putPasswordReset({ getters }, username) {
		try {
			getters.apiPut('auth/pw-reset', { data: { username } });
		} catch (e) {
			console.log(`sending password reset failed. Message:  ${e} `);
		}
	},

	/**
   * This function only gets called when a token is found in localStorage.
   * @param {*} token the token currently in localStorage
   */
	async tokenPresentOnAppLoad({ state, commit, dispatch, getters }, token) {
		[ 'user', 'membership', 'view_ticket' ].forEach((table) => {
			commit('setValue', {
				storeKey: table,
				storeValue: JSON.parse(localStorage.getItem(table))
			});
		});

		commit('setValue', {
			storeKey: 'token',
			storeValue: token
		});

		setTimeout(async () => {
			// this runs on a delay and refreshes the data.
			// api will validate token and get login data package
			let response = [];
			try {
				response = await getters.apiPut('auth/token');
			} catch (err) {
				commit('pushFlash', {
					title: 'Authentication Error',
					message: 'Sorry, could not automatically log in this time.  Please log in again.',
					variant: 'warning',
					duration: 5000
				});
			}

			// copy validated token from localStorage to state
			commit('setValue', {
				storeKey: 'token',
				storeValue: token
			});

			// response contains table data for $store.state
			dispatch('tablesArrayToStoreAndLocalStorage', response.data.tablesArray);
		}, 100);

		const { returnTo } = state;
		if (returnTo) {
			commit('setValue', { storeKey: 'returnTo', storeValue: null });
			try {
				await router.push(returnTo); // eslint-disable-line no-undef
			} catch (e) {
				console.log('executing second try to get to returnTo');
				/* navigating to internal pages (like LessonDetails) often fails once, but succeeds on a retry because? all the prefetching is done */
				router.push(returnTo); // eslint-disable-line no-undef
			}
		}
	}
};
