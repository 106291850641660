/* eslint-disable no-param-reassign */

import { IVuexRootState } from "@/types";



export default {

  setUserNewsSeen(state: IVuexRootState, newsId: number): void {
    state.user!.news_seen = newsId;
  }



}
