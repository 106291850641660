import jwtDecode from "jwt-decode";
import apiFetch from './GApiFetch';
import joinedDecks from './GJoinedDecks';
import joinedLessons from './GJoinedLessons';
import joinedProblems from './GJoinedProblems';
import mediaQueries from './GMediaQueries';
import memberships from './GMemberships';
import subscriptions from './GSubscriptions';
import user from './GUser';
import tags from './GTags';
import tsQueues from './GTsQueues';



export default {
  ...apiFetch,
  ...joinedDecks,
  ...joinedLessons,
  ...joinedProblems,
  ...mediaQueries,
  ...memberships,
  ...subscriptions,
  ...user,
  ...tags,
  ...tsQueues,



  /** ***************************** */
  /**           Token             * */
  /** ***************************** */

  tokenExpirationDate: (state) => {
    if (state.token) {
      const payload = jwtDecode(state.token);
      return new Date(payload.exp * 1000);
    }
    return null;
  },

  tokenRefreshDate: (state) => {
    // refresh date for a token = issue date (seconds since epoch) + refresh_interval.
    // note expiration interval and refresh interval are set in the server's constants file
    // and injected into the token at creation.  Initially setting refresh interval at 30 minutes.
    if (state.token) {
      const payload = jwtDecode(state.token);
      return new Date(payload.iat * 1000 + Number(payload.refresh_interval));
    }
    return null;
  },





  /** ****************************** */
  /**           Other             * */
  /** ****************************** */



  // returns object of type IForumTree
  forumTree(state) {
    /* eslint-disable no-param-reassign */
    const tree = {};
    tree.categories = [...state.category];

    // put topics into categories
    tree.categories.forEach((category) => {
      category.topics = state.topic.filter((top) => top.cat_id === category.cat_id);
      for (let i = category.topics.length - 1; i >= 0; i -= 1) {
        const thisTopicsPosts = state.post.filter(
          (post) =>
            post.topic_id === category.topics[i].topic_id &&
            (post.publish === 1 || post.author_id === state.user.us_id) ,
        );
        if (thisTopicsPosts.length === 0) {
          // if no posts in topic, cut it out of array
          category.topics.splice(i, 1);
        } else {
          category.topics[i].posts = thisTopicsPosts;
          // add fields to topics object for latest post
          category.topics[i].latestPostId = thisTopicsPosts[thisTopicsPosts.length - 1].post_id;
          category.topics[i].latestPostAuthor =
            thisTopicsPosts[thisTopicsPosts.length - 1].author_name;
          category.topics[i].latestPostDate = thisTopicsPosts[thisTopicsPosts.length - 1].created;
        }
      }
      // add fields to categories obj for the latest post
      category.latestPostId = 0;
      category.latestPostAuthor = '';
      category.latestPostDate = '';
      category.topics.forEach((top) => {
        if (top.latestPostId > category.latestPostId) {
          // this topic has a more recent post
          category.latestPostId = top.latestPostId;
          category.latestPostAuthor = top.latestPostAuthor;
          category.latestPostDate = top.latestPostDate;
        }
      });
    });
    /* eslint-enable no-param-reassign */
    return tree;
  },

  getTeacherName: (state) => (teacherId) =>
    state.teacher.find((t) => t.tr_id === teacherId).fullname,



  lastRecord: (state) => (tableName) => state[tableName].slice(-1)[0],
  // returns the last record in a table




};
