/* eslint-disable no-param-reassign */
import { IJoinedTags, ITag, IVuexRootState } from "@/types";


interface PayloadA {
  problemId: number;
  tagId: number;
  tagText: string;
}

interface PayloadT {
  tagId: number;
  tagText: string;
}


export default {

  addNewTagToMyTags(state: IVuexRootState, tagText: string): void {
    if (state.myTags.includes(tagText)) return;
    state.myTags.push(tagText);
  },

  addNewTagToTag(state: IVuexRootState, { tagId: tag_id, tagText: text }: PayloadT): void {
    const result = state.tag.find(t => t.tag_id === tag_id);
    if (!result) state.tag.push({ tag_id, text });
  },

  addProblemToCollection(state: IVuexRootState, payload: PayloadA): void {
    state.joinedTags.push({
      us_id: state.user!.us_id,
      pb_id: payload.problemId,
      tag_id: payload.tagId,
      text: payload.tagText,
    })
  },

  // when problems get deleted, clear the tag tables before reloading from db
  clearJoinedTagTable(state: IVuexRootState): void {
    state.joinedTags = [];
  },

  loadJoinedTags(state: IVuexRootState, rows: IJoinedTags[]): void {
    state.joinedTags = rows;
  },

  loadMyTags(state: IVuexRootState, tags: string[]): void {
    state.myTags = tags;
  },

  loadTags(state: IVuexRootState, rows: ITag[]): void {
    state.tag = rows;
  },

  deleteProblemsFromJoinedTags(state: IVuexRootState, tagText: string): void {
    let index = state.joinedTags.findIndex(jt=>jt.text === tagText)
    while (index !== -1){
      state.joinedTags.splice(index, 1);
      index = state.joinedTags.findIndex(jt=>jt.text === tagText)
    }
  },

  deleteTagFromMyTags(state: IVuexRootState, tagText: string): void {
    const index = state.myTags.findIndex(jt=>jt === tagText)
    if (index === -1) return;
    state.myTags.splice(index, 1);
  },

  deleteTagFromTags(state: IVuexRootState, tagText: string): void {
    const index = state.tag.findIndex(t=>t.text === tagText)
    if (index === -1) return;
    state.tag.splice(index, 1);
  },

  removeProblemFromCollection(state: IVuexRootState, payload: PayloadA): void {
    const i = state.joinedTags.findIndex(jt=>(
      jt.pb_id === payload.problemId &&
      jt.tag_id === payload.tagId
    ))
    state.joinedTags.splice(i, 1);
  },


}
