export default {
	async getMemberships({ getters }) {
		const response = await getters.apiGet('fetch/memberships');
    return response.data.memberships;
	},

	replaceMemberships({ commit }, newMembershipArray) {
		commit('deleteAllMemberships');
		commit('saveMemberships', newMembershipArray);
	}
};
