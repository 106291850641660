/* eslint-disable no-param-reassign */
import { INews, IVuexRootState } from "@/types";

export default {


  saveNews(state: IVuexRootState, news: INews[]): void {
    state.news = news;
    state.newsFetchedAt = new Date();
  },


}
