/* eslint-disable no-param-reassign */
import { IStats, IVuexRootState } from "@/types";




export default {


  popStats(state: IVuexRootState) {
    return state.stats.pop()
  },

  // most recent date is in index 0
  sortStats(state: IVuexRootState) {
    state.stats.sort((a, b) => new Date(b.today).valueOf() - new Date(a.today).valueOf())
  },

  unshiftStats(state: IVuexRootState, newRecord: IStats) {
    state.stats.unshift(newRecord);
  },

  updateTodaysStatsRecord(state: IVuexRootState, statsRecord: IStats){
    state.stats[0] = statsRecord;
  }



}