<script>
import LandingHeader from './LandingHeader';
import WideNav from './WideNav';
import WideNavLoggedOut from './WideNavLoggedOut';
import MobileNav from './MobileNav';

/**
 * All menu content is defined on this page and passed through to the
 * nav components as "menu" prop.  There is one array for when logged in,
 * and another array for when logged out -- defined in the data object.
 */
export default {
  name: 'NavRoot',
  components: {
    LandingHeader,
    MobileNav,
    WideNav,
    WideNavLoggedOut,
  },
  data() {
    return {
      menus: [
        {
          main: 'Home',
          mainIcon: ['fas', 'home'],
          sub: 'Home',
          to: '/',
          subMenu: false,
          adminOnly: false,
        },
        {
          main: 'Search',
          mainIcon: ['fas', 'search'],
          sub: 'Lessons',
          to: '/search',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Search',
          mainIcon: ['fas', 'search'],
          sub: 'Problem Collections',
          to: '/collections',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Search',
          mainIcon: ['fas', 'search'],
          sub: '',
          to: '',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Store',
          mainIcon: ['fas', 'shopping-cart'],
          sub: `Store`,
          to: '/store',
          subMenu: false,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'Quick Start',
          to: '/help/quick-start',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'FAQ',
          to: '/help/faq',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'Training System',
          to: '/help/ts',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'Student-made Problems',
          to: '/help/own-problems',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'Pvt Lessons',
          to: '/help/pvt_lessons',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Help',
          mainIcon: ['fas', 'question-circle'],
          sub: 'Report Bug',
          to: '/help/bugreport',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Info',
          mainIcon: ['fas', 'info-circle'],
          sub: 'Forum',
          to: '/info/forum',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Info',
          mainIcon: ['fas', 'info-circle'],
          sub: 'News',
          to: '/info/news',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Info',
          mainIcon: ['fas', 'info-circle'],
          sub: 'About Guo Juan',
          to: '/info/aboutGJ',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Info',
          mainIcon: ['fas', 'info-circle'],
          sub: `GJ's Blog`,
          to: '/info/gj-blog',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Info',
          mainIcon: ['fas', 'info-circle'],
          sub: `Contact Us`,
          to: '/contact',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `My Profile`,
          to: '/user/myProfile',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `My Account`,
          to: '/user/myAccount',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `Preferences`,
          to: '/user/preferences',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `Stats`,
          to: '/user/stats',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `Lecture History`,
          to: '/user/myLectHist',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `Gift Certificates`,
          to: '/user/myGCs',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'My Stuff',
          mainIcon: ['fas', 'user'],
          sub: `Logout`,
          to: '',
          subMenu: true,
          adminOnly: false,
        },
        {
          main: 'Admin',
          sub: 'Image Upload',
          to: '/admin/image',
          subMenu: true,
          adminOnly: true,
        },
        { main: 'Admin', sub: 'Lecture', to: '/admin/lecture/0', subMenu: true, adminOnly: true },
        { main: 'Admin', sub: 'Make News', to: '/admin/newNews', subMenu: true, adminOnly: true },
        {
          main: 'Admin',
          sub: 'Moderation',
          to: '/admin/moderation',
          subMenu: true,
          adminOnly: true,
        },
        { main: 'Admin', sub: 'Payments', to: '/admin/payments', subMenu: true, adminOnly: true },
        { main: 'Admin', sub: 'Users', to: '/admin/users', subMenu: true, adminOnly: true },
      ],
      loggedOutMenus: [
        {
          main: `GJ's Blog`,
          sub: `GJ's Blog`,
          to: '/info/gj-blog',
          subMenu: false,
          adminOnly: false,
        },
        {
          main: `Register`,
          sub: `Register`,
          to: '/register',
          subMenu: false,
          adminOnly: false,
        },
        {
          main: 'Log In',
          sub: `Log In`,
          to: '/login',
          subMenu: true,
          adminOnly: false,
        },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    navToShow() {
      if (this.$route.path === '/landing' || this.$route.path === '/register') return 'landing';
      if (!this.isLoggedIn && this.$store.getters.menuStyle === 'mobile') return 'wideLoggedOut';
      if (!this.isLoggedIn) return 'wideLoggedOut';
      if (this.$store.getters.menuStyle === 'mobile') return 'mobileLoggedIn';
      return 'wideLoggedIn';
    },
  },
};
</script>

<template>
  <div>
    <!-- landing-header is position: fixed -->
    <landing-header v-if="navToShow === 'landing'" />

    <!-- mobile nav -->
    <mobile-nav v-if="navToShow === 'mobileLoggedIn'" :menus="menus" />
    <mobile-nav-logged-out v-if="navToShow === 'mobileLoggedOut'" :menus="loggedOutMenus" />

    <!-- normal nav is 3.5rem high, position: fixed -->
    <wide-nav v-if="navToShow === 'wideLoggedIn'" :menus="menus" />
    <wide-nav-logged-out v-if="navToShow === 'wideLoggedOut'" :menus="loggedOutMenus" />
  </div>
</template>
