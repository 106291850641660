import { addMinutes, isPast } from 'date-fns';
import { IJoinedDeck } from '@/types';

export default {
  // api call to change (edit) a deck, api returns JoinedDeck object
  async updateDeckProperties({ commit, getters }: any, deck: Partial<IJoinedDeck>): Promise<void> {
    try {
      const response = (await getters.apiPut('fetch/deck', { data: { deck } })) as {
        data: IJoinedDeck;
      };
      commit('replaceDeck', response.data);
    } catch (e) {
      const err = e as Error;
      console.log(err);
      commit('pushFlash', {
        title: 'There was an error while saving changes to the Deck to the server',
        message: `If problem persists, please send a bug report.  Error:  ${err.message}`,
        variant: 'warning',
        duration: 8000,
      });
    }
  },

  // get decks user is allowed to see
  async fetchDecks({ state, commit, getters }: any): Promise<void> {
    // if decks[] is empty or more than 10 minutes old, fetch
    if (!state.joinedDecks.length || isPast(addMinutes(state.decksFetchedAt, 10))) {
      try {
        const response = await getters.apiGet('fetch/deck');
        commit('saveDecks', response.data);
        commit('setDeckFetchTime');
      } catch (e) {
        const err = e as Error;
        console.log(err);
        commit('pushFlash', {
          title: 'There was an error while fetching Decks:',
          message: `If problem persists, please send a bug report.  Error:  ${err.message}`,
          variant: 'warning',
          duration: 8000,
        });
      }
    }
  },

  // count how many students have added problems to TS book
  async getCountsOfStudentsStudyingCards(
    { getters }: any,
    problemIds: number[],
  ): Promise<{ problemId: number; numStudying: number }[]> {
    const baseUrl = '/trainingSystem/count_students_d';
    const params = `problemIds=${problemIds.toString()}`;
    const response = await getters.apiGet(`${baseUrl}?${params}`);
    return response.data as { problemId: number; numStudying: number }[];
  },

  // api call for new deck, api returns JoinedDeck object
  async newDeck({ commit, getters }: any, deck: Partial<IJoinedDeck>): Promise<void> {
    try {
      const response = await getters.apiPut('fetch/deck', { data: { deck } });
      commit('pushDeck', response.data);
    } catch (e) {
      const err = e as Error;
      console.log(err);
      commit('pushFlash', {
        title: 'There was an error while saving the new Deck to the server',
        message: `If problem persists, please send a bug report.  Error:  ${err.message}`,
        variant: 'warning',
        duration: 4000,
      });
    }
  },
};
